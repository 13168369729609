import React, {
	useCallback,
	useRef,
	Fragment,
	useState,
	useEffect,
} from 'react';
import QRCode from 'react-qr-code';
import html2pdf from 'html2pdf.js';

// chakra
import {
	Box,
	Button,
	Text,
	Grid,
	GridItem,
	Stack,
	StackDivider,
	Divider,
	List,
	ListItem,
	UnorderedList,
	Container,
	Flex,
	Image,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	Hide,
} from '@chakra-ui/react';

const LandlordMarketingMaterial = ({
	propertyId,
	propertyImage,
	listPrice,
	monthlyRent,
	bedrooms,
	bathrooms,
	sqft,
	yearBuilt,
	propertyAddress,
	userType,
	agentFirstName = '',
	agentLastName = '',
	agentEmail = '',
	agentContactNumber = '',
	agentBrokerage = '',
}) => {
	const ref = useRef(null);

	const [displayAgentFirstName, setDisplayAgentFirstName] = useState('');
	const [displayAgentLastName, setDisplayAgentLastName] = useState('');
	const [displayAgentEmail, setDisplayAgentEmail] = useState('');
	const [displayAgentContactNumber, setDisplayAgentContactNumber] =
		useState('');
	const [displayAgentBrokerage, setDisplayAgentBrokerage] = useState('');

	const formattedAddress = propertyAddress.replace(/[^a-zA-Z0-9]/g, '_');

	const baseOptions = {
		margin: 0,
		image: { type: 'jpeg', quality: 0.98 },
		html2canvas: {
			scale: 2,
			useCORS: true,
			allowTaint: true,
		},
		jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' },
	};

	const formatPhoneNumber = (phoneNumber) => {
		const cleaned = phoneNumber.replace(/\D/g, '');
		const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
		if (match) {
			return `(${match[1]}) ${match[2]}-${match[3]}`;
		}
		return phoneNumber;
	};

	function numberWithCommas(num, digits) {
		// return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
		const value = num.toLocaleString(
			undefined, // leave undefined to use the visitor's browser
			// locale or a string like 'en-US' to override it.
			{ minimumFractionDigits: digits }
		);

		return value;
	}

	const generateOutput = (type) => {
		const element = ref.current;

		setTimeout(() => {
			const generator = html2pdf().set(baseOptions).from(element);

			if (type === 'pdf') {
				generator
					.toPdf()
					.get('pdf')
					.then((pdf) => {
						pdf.setProperties({
							title: `${formattedAddress}_Listing_Details`,
						});
						const blobUrl = URL.createObjectURL(pdf.output('blob'));
						window.open(blobUrl);
					});
			} else {
				generator.outputImg().then((img) => {
					const link = document.createElement('a');
					link.href = img.src;
					link.download = `${formattedAddress}_Listing_Details.jpg`;
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				});
			}
		}, 500);
	};

	const onGeneratePdf = () => generateOutput('pdf');
	const onGenerateImage = () => generateOutput('image');

	useEffect(() => {
		if (agentEmail === 'ram+30@utopia.cx') {
			setDisplayAgentFirstName('David');
			setDisplayAgentLastName('Johnson');
			setDisplayAgentEmail('support@utopia.cx');
			setDisplayAgentContactNumber('8449573700');
			setDisplayAgentBrokerage('Utopia Homes');
		} else {
			setDisplayAgentFirstName(agentFirstName);
			setDisplayAgentLastName(agentLastName);
			setDisplayAgentEmail(agentEmail);
			setDisplayAgentContactNumber(agentContactNumber);
			setDisplayAgentBrokerage(agentBrokerage);
		}
	}, [
		agentFirstName,
		agentLastName,
		agentEmail,
		agentContactNumber,
		agentBrokerage,
	]);

	return (
		<Fragment>
			<Box display="none">
				<Flex
					ref={ref}
					w="1056px"
					h="815px"
					align="center"
					justify="center"
					bg="white"
				>
					<Box p="4">
						<Stack
							direction="row"
							w="100%"
							divider={
								<StackDivider
									borderColor="gray.200"
									borderWidth="3px"
								/>
							}
						>
							<Flex
								align="center"
								justify="center"
								maxW="320px"
							>
								<Container>
									<Flex
										direction="column"
										gap="8"
									>
										<Box>
											<Text
												fontSize="2xl"
												fontWeight="bold"
												mb="4"
											>
												Utopia Homes
											</Text>
											<Flex
												direction="column"
												gap="2"
											>
												<Text fontWeight="semibold">
													Looking to own a home but
													not able to get a mortgage?
												</Text>
												<Text fontWeight="semibold">
													Utopia can help! Our
													innovative "Rent, Earn, and
													Own" program is designed to
													make homeownership
													accessible to everyone.
												</Text>
											</Flex>
										</Box>

										<Box>
											<Text
												fontSize="xl"
												fontWeight="bold"
												mb="4"
											>
												How It Works
											</Text>
											<Stack>
												<Text>
													<b>Get Pre-Approved:</b>{' '}
													Apply for free in under 3
													minutes to discover your
													home-buying budget. No
													credit score impact, and
													there's no obligation to
													proceed.
												</Text>
												<Text>
													<b>Find Your Dream Home:</b>{' '}
													Choose from Utopia's
													listings or bring your own!
													Work with our agents or
													yours to find the perfect
													match for you.
												</Text>
												<Text>
													<b>
														Move In and Build
														Equity:
													</b>{' '}
													Rent your home while we help
													you transition to
													homeownership. Our program
													boosts your monthly savings
													for a down payment by
													sharing in the appreciation
													of your home's value.
												</Text>
											</Stack>
										</Box>
									</Flex>
								</Container>
							</Flex>

							<Flex
								w="100%"
								align="center"
								justify="center"
							>
								<Container maxW="100%">
									<Flex justify="right">
										<Image
											src="https://i.imgur.com/avkbaJm.png"
											alt="Utopia Homes Logo"
											maxW="90px"
											w="100%"
											mb="4"
										/>
									</Flex>

									<Box
										position="relative"
										width="100%"
										height="350px"
										overflow="hidden"
										borderRadius="md"
										mb="4"
									>
										<Image
											position="absolute"
											top="50%"
											left="50%"
											transform="translate(-50%, -50%)"
											src={propertyImage}
											alt="Property Hero Image"
											minW="100%"
											minH="100%"
											style={{
												objectFit: 'cover',
											}}
										/>
									</Box>

									<Stack
										direction="row"
										divider={
											<StackDivider
												borderColor="gray.200"
												borderWidth="3px"
											/>
										}
										gap="4"
									>
										<Flex w="100%">
											<Box mr="6">
												<Text
													fontSize="xs"
													fontWeight="bold"
													color="gray.500"
												>
													Single Family Home
												</Text>
												<Text
													fontSize="lg"
													fontWeight="bold"
												>
													{propertyAddress}
												</Text>
												<Text>
													{bedrooms} bed, {bathrooms}{' '}
													bath,{' '}
													{numberWithCommas(sqft)} sq
													ft, built {yearBuilt}
												</Text>
											</Box>
											<Box>
												<Text
													fontSize="xs"
													fontWeight="bold"
													color="gray.500"
												>
													List price
												</Text>
												<Text
													fontSize="4xl"
													fontWeight="bold"
												>
													${listPrice}
												</Text>
											</Box>
										</Flex>
										<Flex direction="column">
											<Text
												fontSize="xs"
												fontWeight="bold"
												color="gray.500"
											>
												Payment Options
											</Text>
											<Text>
												<b>${monthlyRent}</b> / month
											</Text>
											<Text>
												3% down, 5% savings for 36
												months
											</Text>
										</Flex>
									</Stack>

									<Divider
										borderColor="gray.200"
										borderWidth="3px"
										my="4"
									/>

									<Flex
										w="100%"
										justify="space-between"
										mb="4"
										align="start"
									>
										{userType === 'agent' && (
											<Box>
												<Text
													fontSize="lg"
													fontWeight="bold"
													mb="2"
												>
													{displayAgentFirstName}{' '}
													{displayAgentLastName}
												</Text>
												<Stack spacing="2">
													<Flex>
														<Text fontSize="lg">
															💼 Brokerage:{' '}
															{
																displayAgentBrokerage
															}
														</Text>
													</Flex>
													<Flex>
														<Text fontSize="lg">
															✉️{' '}
															{displayAgentEmail}
														</Text>
													</Flex>
													<Flex>
														<Text fontSize="lg">
															📱{' '}
															{formatPhoneNumber(
																displayAgentContactNumber
															)}
														</Text>
													</Flex>
												</Stack>
											</Box>
										)}
										{userType === 'landlord' && (
											<Box>
												<Text
													fontSize="lg"
													fontWeight="bold"
													mb="2"
												>
													Utopia Homes
												</Text>
												<Stack spacing="2">
													<Flex>
														<Text fontSize="lg">
															🌐
															www.utopiahomes.us
														</Text>
													</Flex>
													<Flex>
														<Text fontSize="lg">
															📨 support@utopia.cx
														</Text>
													</Flex>
													<Flex>
														<Text fontSize="lg">
															📱 844-957-3700
														</Text>
													</Flex>
												</Stack>
											</Box>
										)}
										<Flex direction="column">
											<Box
												height="auto"
												margin="0 auto"
												maxWidth="130"
												width="100%"
												mb="2"
											>
												<QRCode
													size={130}
													style={{
														height: 'auto',
														maxWidth: '100%',
														width: '100%',
													}}
													value={`https://dash.utopiahomes.us/listing/${propertyId}`}
													viewBox={`0 0 256 256`}
												/>
											</Box>
											<Flex justify="center">
												<Text
													fontSize="xs"
													fontWeight="bold"
													color="gray.500"
												>
													Scan QR for more info
												</Text>
											</Flex>
										</Flex>
									</Flex>
								</Container>
							</Flex>
						</Stack>
						<Divider
							borderColor="gray.200"
							borderWidth="3px"
						/>
						<Flex
							w="100%"
							justify="center"
							align="center"
							gap="4"
							py="4"
						>
							<Flex align="center">
								<Text mr="2">🌐</Text>
								<Text>www.utopiahomes.us</Text>
							</Flex>
							<Divider
								borderColor="gray.900"
								borderWidth="1px"
								orientation="vertical"
								h="10px"
							/>
							<Flex align="center">
								<Text mr="2">📨</Text>
								<Text>support@utopia.cx</Text>
							</Flex>
						</Flex>
					</Box>
				</Flex>
			</Box>

			<Box
				p="4"
				pt="0"
			>
				<Flex
					direction={{ base: 'column', md: 'row' }}
					gap="4"
					mb="4"
				>
					<Button
						w="100%"
						colorScheme="blue"
						onClick={onGeneratePdf}
					>
						Get Marketing PDF
					</Button>
					<Button
						w="100%"
						colorScheme="blue"
						onClick={onGenerateImage}
					>
						Get Social Marketing
					</Button>
				</Flex>
			</Box>
		</Fragment>
	);
};

export default LandlordMarketingMaterial;
