import { DeleteIcon } from '@chakra-ui/icons';
import { Button, Text, useDisclosure } from '@chakra-ui/react';
import CustomTable from 'components/customTable';
import moment from 'moment-timezone';
import React, { useState, useEffect } from 'react';
// import ApplicationDetails from './ApplicationDetails';
import PropertyModal from './PropertyModal';

const PropertiesList = (props) => {
	const {
		data,
		setSelectedUser,
		selectedUser,
		loading,
		getStorageData,
		applicationData,
		storageData,
		setStorageData,
		setApplicationData,
		handleDelete,
		userLoading,
	} = props;

	const { isOpen, onOpen, onClose } = useDisclosure();

	const [tableData, setTableData] = useState(false);

	const closeModal = () => {
		setSelectedUser(null);
		setStorageData(null);
	};

	const columns = React.useMemo(
		() => [
			{
				header: () => <span>#</span>,
				accessorKey: 'serialNo',
				enableSorting: false,
				cell: (row) => {
					return <div>{row?.row?.index + 1}</div>;
				},
			},
			{
				accessorKey: 'address',
				header: () => <span>Address</span>,
				footer: (props) => props.column.id,
			},
			{
				accessorKey: 'status',
				header: () => <span>Status</span>,
				footer: (props) => props.column.id,
			},
			{
				accessorKey: 'submissionDate',
				header: () => <span>Submission Date</span>,
				footer: (props) => props.column.id,
				sortType: 'datetime',
				cell: (row) => {
					let value = row.getValue();
					return (
						<Text>
							{value
								? moment(value)
										.tz('America/New_York')
										.format('DD MMM YYYY,  hh:mm a')
								: ''}
						</Text>
					);
				},
			},
			{
				accessorKey: 'userType',
				header: () => <span>User Type</span>,
				footer: (props) => props.column.id,
			},
			{
				accessorKey: 'email',
				header: () => <span>Email</span>,
				footer: (props) => props.column.id,
			},
			{
				accessorKey: 'actions',
				header: () => <span>Actions</span>,
				footer: (props) => props.column.id,
				cell: (row) => {
					return (
						<Button
							width={'100%'}
							maxH={'25px'}
							background="transparent"
						>
							<DeleteIcon color="red.400" />
						</Button>
					);
				},
			},
		],
		[]
	);

	useEffect(() => {
		if (data) {
			let tableList = [];
			for (let i = 0; i < data.length; i++) {
				const currentData = data[i];
				tableList.push({
					id: i,
					address: currentData?.stepTwo[3]?.answer,
					status: currentData?.status,
					submissionDate: moment
						.tz(
							currentData?.timeSubmitted?.toDate(),
							'America/New_York'
						)
						.format('MM/DD/YYYY hh:mm A'),
					userType: currentData?.userData?.type,
					email: currentData?.userData?.email,
					property: currentData,
				});
			}
			setTableData(tableList);
		}
	}, [data]);

	return (
		<>
			<CustomTable
				data={tableData}
				columns={columns}
				onRowPress={(data) => {
					setSelectedUser(data?.property);
					onOpen();
					// getStorageData(tableData.id);
					// setSelectedUser(tableData);
					// setApplicationData(tableData?.application);
				}}
				loading={userLoading}
				onActionPress={(tableData) => {
					const name =
						tableData?.legalName?.firstName +
						' ' +
						tableData?.legalName?.lastName;
					handleDelete(tableData.id, name);
				}}
			/>

			<PropertyModal
				isOpen={isOpen}
				onClose={onClose}
				selectedProperty={selectedUser}
				setSelectedProperty={setSelectedUser}
			/>

			{/* <ApplicationDetails
				closeModal={closeModal}
				selectedUser={selectedUser}
				isOpen={Boolean(selectedUser)}
				loading={loading}
				applicationData={applicationData}
				storageData={storageData}
			/> */}
		</>
	);
};

export default PropertiesList;
