// Chakra Imports
import {
	Avatar,
	Flex,
	Icon,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Spinner,
	Text,
	useColorModeValue,
} from '@chakra-ui/react';

import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import { SidebarResponsive } from 'components/sidebar/Sidebar';
import { useAuth } from 'contexts/AuthContext';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { MdNotificationsNone } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import routes from 'routes.js';

const HeaderLinks = (props) => {
	const { secondary } = props;
	const [searchValue, setSearchValue] = useState('');

	const { handleSignOut, authLoading, setSearchresults } = useAuth();

	const {
		allApplications,
		pendingApplications,
		approvedApplications,
		rejectedApplications,
		moreInfoRequestApplications,
		startedApplications,
		allSubmittedApplications,
		inReviewApplications,
		pausedApplications,
		offerExtended,
		offerAccepted,
		inspectionComplete,
		preclosingComplete,
		offerClosed,
		conditionallyApproved,
		landlords,
		properties,
		rejectedProperties,
		inReviewProperties,
		acceptedProperties,
	} = useSelector((state) => state.user);

	const {
		agents,
		invites,
		agentInvites,
		clientInvites,
		agentLandlordInvites,
	} = useSelector((state) => state.agent);
	const { initiatedOffers } = useSelector((state) => state.offer);

	const navbarIcon = useColorModeValue('gray.400', 'white');
	let menuBg = useColorModeValue('white', 'navy.800');
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const textColorBrand = useColorModeValue('brand.700', 'brand.400');

	const borderColor = useColorModeValue(
		'#E6ECFA',
		'rgba(135, 140, 189, 0.3)'
	);
	const shadow = useColorModeValue(
		'14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
		'14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
	);

	const location = useLocation();

	/**
	 * search Functionality
	 */

	const arraySearch = (array, keyword, pathname = '') => {
		const searchTerm = keyword.toLowerCase();

		if (
			pathname === '/admin/properties/properties-all' ||
			pathname === '/admin/properties/properties-in-review' ||
			pathname === '/admin/properties/properties-accepted' ||
			pathname === '/admin/properties/properties-rejected'
		) {
			return array.filter((value) => {
				return (
					value?.userData?.email
						?.toString()
						?.toLowerCase()
						.match(new RegExp(searchTerm, 'g')) ||
					value?.userData?.name
						?.toLowerCase()
						.match(new RegExp(searchTerm, 'g')) ||
					value?.userData?.clientName
						?.toLowerCase()
						.match(new RegExp(searchTerm, 'g')) ||
					value?.userData?.clientEmail
						?.toLowerCase()
						.match(new RegExp(searchTerm, 'g')) ||
					value?.userData?.agentName
						?.toLowerCase()
						.match(new RegExp(searchTerm, 'g')) ||
					value?.userData?.agentEmail
						?.toLowerCase()
						.match(new RegExp(searchTerm, 'g'))
				);
			});
		}

		return array.filter((value) => {
			return (
				value.email
					?.toString()
					?.toLowerCase()
					.match(new RegExp(searchTerm, 'g')) ||
				value.name?.toLowerCase().match(new RegExp(searchTerm, 'g')) ||
				value.clientName
					?.toLowerCase()
					.match(new RegExp(searchTerm, 'g')) ||
				value.clientEmail
					?.toLowerCase()
					.match(new RegExp(searchTerm, 'g')) ||
				value.agentName
					?.toLowerCase()
					.match(new RegExp(searchTerm, 'g')) ||
				value.agentEmail
					?.toLowerCase()
					.match(new RegExp(searchTerm, 'g'))
			);
		});
	};

	const handleSearch = (searchText) => {
		let pathname = location.pathname;

		let arr = [];

		if (pathname === '/admin/application/all') {
			arr = allApplications;
		} else if (pathname === '/admin/application/started') {
			arr = startedApplications;
		} else if (pathname === '/admin/applications/all-submitted') {
			arr = allSubmittedApplications;
		} else if (pathname === '/admin/applications/inbox') {
			arr = pendingApplications;
		} else if (pathname === '/admin/applications/approved') {
			arr = approvedApplications;
		} else if (pathname === '/admin/applications/conditionally-approved') {
			arr = conditionallyApproved;
		} else if (pathname === '/admin/applications/info-required') {
			arr = moreInfoRequestApplications;
		} else if (pathname === '/admin/applications/in-review') {
			arr = inReviewApplications;
		} else if (pathname === '/admin/applications/paused') {
			arr = pausedApplications;
		} else if (pathname === '/admin/applications/rejected') {
			arr = rejectedApplications;
		} else if (pathname === '/admin/applications/offer-extended') {
			arr = offerExtended;
		} else if (pathname === '/admin/applications/offer-accepted') {
			arr = offerAccepted;
		} else if (pathname === '/admin/applications/inspection-complete') {
			arr = inspectionComplete;
		} else if (pathname === '/admin/applications/preclosing-complete') {
			arr = preclosingComplete;
		} else if (pathname === '/admin/applications/offer-closed') {
			arr = offerClosed;
		} else if (pathname === '/admin/agents') {
			arr = agents;
		} else if (pathname === '/admin/client_invites') {
			arr = invites;
		} else if (pathname === '/admin/agent_invites') {
			arr = agentInvites;
		} else if (pathname === '/admin/landlord_invites') {
			arr = agentLandlordInvites;
		} else if (pathname === '/admin/offer-initiated') {
			arr = initiatedOffers;
		} else if (pathname === '/admin/homebuyer_invites') {
			arr = clientInvites;
		} else if (pathname === '/admin/listings/properties-all') {
			arr = clientInvites;
		} else if (pathname === '/admin/properties-initiated') {
			arr = [];
		} else if (pathname === '/admin/landlords') {
			let landlordUsersArr = [];
			for (let i = 0; i < landlords.length; i++) {
				const currentLandlordData = landlords[i];

				if (currentLandlordData?.type === 'landlord') {
					landlordUsersArr.push(currentLandlordData);
				}
			}
			arr = landlordUsersArr;
		} else if (pathname === '/admin/properties/properties-all') {
			arr = properties;
		} else if (pathname === '/admin/properties/properties-in-review') {
			arr = inReviewProperties;
		} else if (pathname === '/admin/properties/properties-accepted') {
			arr = acceptedProperties;
		} else if (pathname === '/admin/properties/properties-rejected') {
			arr = rejectedProperties;
		}

		const searchRes = arraySearch(arr, searchText, pathname);

		setSearchresults(searchRes);
	};

	const handleTyping = (text) => {
		handleSearch(text);
		setSearchValue(text);
	};

	useEffect(() => {
		setSearchresults(null);
		setSearchValue('');
	}, [location.pathname]);

	return (
		<>
			<Flex
				w={{ sm: '100%', md: 'auto' }}
				alignItems="center"
				flexDirection="row"
				bg={menuBg}
				flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
				p="10px"
				borderRadius="30px"
				boxShadow={shadow}
			>
				<SearchBar
					mb={secondary ? { base: '10px', md: 'unset' } : 'unset'}
					me="10px"
					borderRadius="30px"
					onChange={(e) => handleTyping(e.target?.value)}
					value={searchValue}
				/>

				<SidebarResponsive routes={routes} />
				<Menu>
					<MenuButton p="0px">
						<Icon
							mt="6px"
							as={MdNotificationsNone}
							color={navbarIcon}
							w="18px"
							h="18px"
							me="10px"
						/>
					</MenuButton>
					<MenuList
						boxShadow={shadow}
						p="20px"
						borderRadius="20px"
						bg={menuBg}
						border="none"
						mt="22px"
						me={{ base: '30px', md: 'unset' }}
						minW={{ base: 'unset', md: '400px', xl: '450px' }}
						maxW={{ base: '360px', md: 'unset' }}
					>
						<Flex
							jusitfy="space-between"
							w="100%"
							mb="20px"
						>
							<Text
								fontSize="md"
								fontWeight="600"
								color={textColor}
							>
								Notifications
							</Text>
							<Text
								fontSize="sm"
								fontWeight="500"
								color={textColorBrand}
								ms="auto"
								cursor="pointer"
							>
								Mark all read
							</Text>
						</Flex>
					</MenuList>
				</Menu>

				<Menu>
					<MenuButton p="0px">
						<Avatar
							_hover={{ cursor: 'pointer' }}
							color="white"
							name="Adela Parkson"
							bg="#11047A"
							size="sm"
							w="40px"
							h="40px"
						/>
					</MenuButton>
					<MenuList
						boxShadow={shadow}
						p="0px"
						mt="10px"
						borderRadius="20px"
						bg={menuBg}
						border="none"
					>
						<Flex
							w="100%"
							mb="0px"
						>
							<Text
								ps="20px"
								pt="16px"
								pb="10px"
								w="100%"
								borderBottom="1px solid"
								borderColor={borderColor}
								fontSize="sm"
								fontWeight="700"
								color={textColor}
							>
								👋&nbsp; Hi,
							</Text>
						</Flex>
						<Flex
							flexDirection="column"
							p="10px"
						>
							<MenuItem
								_hover={{ bg: 'none' }}
								_focus={{ bg: 'none' }}
								color="red.400"
								borderRadius="8px"
								px="14px"
								onClick={handleSignOut}
							>
								{authLoading ? (
									<Spinner />
								) : (
									<Text fontSize="sm">Log out</Text>
								)}
							</MenuItem>
						</Flex>
					</MenuList>
				</Menu>
			</Flex>
		</>
	);
};

HeaderLinks.propTypes = {
	variant: PropTypes.string,
	fixed: PropTypes.bool,
	secondary: PropTypes.bool,
	onOpen: PropTypes.func,
};

export default HeaderLinks;
