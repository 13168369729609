import {
	Timestamp,
	collection,
	doc,
	getCountFromServer,
	getDoc,
	getDocs,
	query,
	setDoc,
	where,
	updateDoc,
	addDoc,
	deleteDoc,
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getDownloadURL, getMetadata, listAll, ref } from 'firebase/storage';
import { supabase } from 'utils/supabase';
// import moment from 'moment'
import {
	setApplicationCompletedData,
	setFromDate,
	setMonthlyStats,
	setStartedApplicationBarData,
	setToDate,
} from 'store/reducers/graphSlice';
import {
	setUsers,
	setDateRangeUsers,
	setLandlords,
} from 'store/reducers/usersSlice';
import { store } from 'store/store';
import { USER_TYPES } from 'utils/constants';
import { db, storage } from 'utils/firebase';
import { convertCommaSeperatedToNormalNumber } from 'utils/helpers';
import { APPLICATION_STATUS } from '../utils/constants';
import * as dayjs from 'dayjs';
import moment from 'moment-timezone';
import axios from 'axios';
import {
	handleGetUsers,
	handleGetAgents,
	handleGetOffers,
	handleGetApplicationResponses,
	handleGetHomebuyerApplicationResponses,
	handleGetCoapplicantApplicationResponses,
	handleGetAgentApplicationResponses,
	handleGetAgentInvites,
} from './data.service';

var utc = require('dayjs/plugin/utc');
var timezone = require('dayjs/plugin/timezone');

const tz = 'America/New_York';

dayjs.extend(utc);
dayjs.extend(timezone);

const getAllUsers = async () => {
	console.log('Running getAllUsers()');
	try {
		let users = [];
		const userRef = collection(db, 'users');

		const docsSnap = await getDocs(userRef);
		docsSnap.forEach((doc) => {
			const data = doc.data();

			if (!data.isDeleted && data.type !== USER_TYPES.AGENT) {
				users.push({
					...data,
					id: doc.id,
					applicationStartDate: data?.applicationStartDate
						? moment
								.tz(
									data?.applicationStartDate?.toDate(),
									'America/New_York'
								)
								.toDate()
						: null,
					displayId: doc.data()?.user_id,
					applicationStatus: doc.data()?.applicationCompleted
						? 'Completed'
						: 'InProgress',
					firstName: doc.data().legalName?.firstName,
					lastName: doc.data().legalName?.lastName,
				});
			}
		});

		users = users.sort((a, b) => {
			return b.applicationStartDate - a.applicationStartDate;
		});

		return users;
	} catch (err) {
		console.log('Error getAllUsers(): ', err);
		return [];
	}
};

const getAllUsers_Optimized = async () => {
	console.log('Running getAllUsers_Optimized()');
	try {
		let { users: usersRaw } = store.getState()?.data;

		let users = [];
		for (let i = 0; i < usersRaw.length; i++) {
			const data = usersRaw[i];

			if (!data.isDeleted && data.type !== USER_TYPES.AGENT) {
				users.push({
					...data,
					id: data.id,
					applicationStartDate: data?.applicationStartDate
						? moment
								.tz(
									data?.applicationStartDate?.toDate(),
									'America/New_York'
								)
								.toDate()
						: null,
					displayId: data?.user_id,
					applicationStatus: data?.applicationCompleted
						? 'Completed'
						: 'InProgress',
					firstName: data?.legalName?.firstName,
					lastName: data?.legalName?.lastName,
				});
			}
		}

		users = users.sort((a, b) => {
			return b.applicationStartDate - a.applicationStartDate;
		});

		return users;
	} catch (err) {
		console.log('Error getAllUsers_Optimized(): ', err);
		return [];
	}
};

const getAllApplicationsData_Internal = async () => {
	console.log('Running getAllApplicationsData_Internal()');
	try {
		const users = await getAllUsers_Optimized();
		const applications = await getAllApplications_Optimized();

		let pending = [],
			rejected = [],
			approved = [],
			all = [],
			moreInfo = [],
			started = [],
			othered = [],
			allSubmitted = [],
			paused = [],
			inReview = [],
			conditionallyApproved = [],
			offerExtended = [],
			offerAccepted = [],
			inspectionComplete = [],
			preclosingComplete = [],
			offerClosed = [],
			inactive = [];

		const allData = users.map((user) => {
			const application = applications.find(
				(application) => application.uid === user.id
			);

			const appStepSeven = application?.stepSeven;
			const username = user.legalName
				? user?.legalName?.firstName + ' ' + user?.legalName?.lastName
				: appStepSeven
				? appStepSeven[0]?.answer +
				  ' ' +
				  appStepSeven[1].answer +
				  ' ' +
				  appStepSeven[2].answer
				: '';

			const firstName =
				user?.legalName?.firstName ?? appStepSeven
					? appStepSeven[0]?.answer
					: '';

			const lastName =
				user?.legalName?.firstName ?? appStepSeven
					? appStepSeven[2]?.answer
					: '';

			const rData = {
				...user,
				name: username,
				firstName,
				lastName,
				application: application ? application : null,
				preQualifiedAmount: application?.stepEight
					? application?.stepEight[0]?.answer
					: 0,
				qualifiedAmount: convertCommaSeperatedToNormalNumber(
					Number(user?.qualifiedAmount)
				),
				submissionDate: application?.timeSubmitted
					? moment
							.tz(
								application?.timeSubmitted?.toDate(),
								'America/New_York'
							)
							.toDate()
					: null,
			};

			const currentStatus = rData.application?.status;

			const otheredAnswerList = rData.application?.stepThree
				? rData.application?.stepThree
				: [];
			const otheredAnswer = otheredAnswerList[2]?.answer
				? otheredAnswerList[2]?.answer
				: '';
			const otheredStatus = otheredAnswer.includes('Other');

			// if (user.applicationCompleted) {
			if (currentStatus === APPLICATION_STATUS.APPROVED) {
				approved.push(rData);
				allSubmitted.push(rData);
			} else if (
				currentStatus === APPLICATION_STATUS.CONDITIONALLY_APROVED
			) {
				conditionallyApproved.push(rData);
				allSubmitted.push(rData);
			} else if (currentStatus === APPLICATION_STATUS.REJECTED) {
				rejected.push(rData);
				allSubmitted.push(rData);
			} else if (
				currentStatus === APPLICATION_STATUS.MORE_INFO_REQUIRED
			) {
				moreInfo.push(rData);
				allSubmitted.push(rData);
			} else if (currentStatus === APPLICATION_STATUS.PAUSED) {
				paused.push(rData);
				allSubmitted.push(rData);
			} else if (currentStatus === APPLICATION_STATUS.IN_REVIEW) {
				inReview.push(rData);
				allSubmitted.push(rData);
			} else if (currentStatus === APPLICATION_STATUS.OFFER_EXTENDED) {
				offerExtended.push(rData);
				allSubmitted.push(rData);
			} else if (currentStatus === APPLICATION_STATUS.OFFER_ACCEPTED) {
				offerAccepted.push(rData);
				allSubmitted.push(rData);
			} else if (
				currentStatus === APPLICATION_STATUS.INSPECTION_COMPLETE
			) {
				inspectionComplete.push(rData);
				allSubmitted.push(rData);
			} else if (
				currentStatus === APPLICATION_STATUS.PRECLOSING_COMPLETE
			) {
				preclosingComplete.push(rData);
				allSubmitted.push(rData);
			} else if (currentStatus === APPLICATION_STATUS.OFFER_CLOSED) {
				offerClosed.push(rData);
				allSubmitted.push(rData);
			} else if (currentStatus === APPLICATION_STATUS.INACTIVE) {
				inactive.push(rData);
				allSubmitted.push(rData);
			} else if (!user.applicationCompleted && otheredStatus) {
				othered.push(rData);
			} else if (!user.applicationCompleted) {
				started.push(rData);
			} else {
				pending.push(rData);
				allSubmitted.push(rData);
			}

			// allSubmitted.push(rData);
			// } else {
			//   started.push(rData);
			// }

			all.push(rData);

			return rData;
		});

		all = all.sort((a, b) => {
			return b.submissionDate - a.submissionDate;
		});

		approved = approved.sort((a, b) => {
			return b.submissionDate - a.submissionDate;
		});

		conditionallyApproved = conditionallyApproved.sort((a, b) => {
			return b.submissionDate - a.submissionDate;
		});

		allSubmitted = allSubmitted.sort((a, b) => {
			return b.submissionDate - a.submissionDate;
		});

		rejected = rejected.sort((a, b) => {
			return b.submissionDate - a.submissionDate;
		});

		moreInfo = moreInfo.sort((a, b) => {
			return b.submissionDate - a.submissionDate;
		});

		paused = paused.sort((a, b) => {
			return b.submissionDate - a.submissionDate;
		});

		inReview = inReview.sort((a, b) => {
			return b.submissionDate - a.submissionDate;
		});

		offerExtended = offerExtended.sort((a, b) => {
			return b.submissionDate - a.submissionDate;
		});

		offerAccepted = offerAccepted.sort((a, b) => {
			return b.submissionDate - a.submissionDate;
		});

		inspectionComplete = inspectionComplete.sort((a, b) => {
			return b.submissionDate - a.submissionDate;
		});

		preclosingComplete = preclosingComplete.sort((a, b) => {
			return b.submissionDate - a.submissionDate;
		});

		offerClosed = offerClosed.sort((a, b) => {
			return b.submissionDate - a.submissionDate;
		});

		started = started.sort((a, b) => {
			return b.applicationStartData - a.applicationStartData;
		});

		othered = othered.sort((a, b) => {
			return b.applicationStartData - a.applicationStartData;
		});

		inactive = inactive.sort((a, b) => {
			return b.applicationStartData - a.applicationStartData;
		});

		return {
			pending,
			rejected,
			approved,
			all,
			moreInfo,
			started,
			othered,
			allSubmitted,
			paused,
			inReview,
			conditionallyApproved,
			offerExtended,
			offerAccepted,
			inspectionComplete,
			preclosingComplete,
			offerClosed,
			inactive,
		};
	} catch (err) {
		console.log('Error getAllApplicationsData_Internal(): ', err);
		return {};
	}
};

const getAllApplicationsData = async () => {
	console.log('Running getAllApplicationsData()');
	try {
		const users = await getAllUsers_Optimized();
		const applications = await getAllApplications_Optimized();

		let pending = [],
			rejected = [],
			approved = [],
			all = [],
			moreInfo = [],
			started = [],
			othered = [],
			allSubmitted = [],
			paused = [],
			inReview = [],
			conditionallyApproved = [],
			offerExtended = [],
			offerAccepted = [],
			inspectionComplete = [],
			preclosingComplete = [],
			offerClosed = [],
			inactive = [],
			waitlistUsers = [],
			landlords = [],
			properties = [],
			inReviewProperties = [],
			rejectedProperties = [],
			acceptedProperties = [],
			initiatedProperties = [];

		const allData = users.map((user) => {
			const application = applications.find(
				(application) => application.uid === user.id
			);

			const appStepSeven = application?.stepSeven;
			const username = user.legalName
				? user?.legalName?.firstName + ' ' + user?.legalName?.lastName
				: appStepSeven
				? appStepSeven[0]?.answer +
				  ' ' +
				  appStepSeven[1].answer +
				  ' ' +
				  appStepSeven[2].answer
				: '';

			const firstName =
				user?.legalName?.firstName ?? appStepSeven
					? appStepSeven[0]?.answer
					: '';

			const lastName =
				user?.legalName?.firstName ?? appStepSeven
					? appStepSeven[2]?.answer
					: '';

			const rData = {
				...user,
				name: username,
				firstName,
				lastName,
				application: application ? application : null,
				preQualifiedAmount: application?.stepEight
					? application?.stepEight[0]?.answer
					: 0,
				qualifiedAmount: convertCommaSeperatedToNormalNumber(
					Number(user?.qualifiedAmount)
				),
				submissionDate: application?.timeSubmitted
					? moment
							.tz(
								application?.timeSubmitted?.toDate(),
								'America/New_York'
							)
							.toDate()
					: null,
			};

			const currentStatus = rData.application?.status;

			const otheredAnswerList = rData.application?.stepThree
				? rData.application?.stepThree
				: [];
			const otheredAnswer = otheredAnswerList[2]?.answer
				? otheredAnswerList[2]?.answer
				: '';
			const otheredStatus = otheredAnswer.includes('Other');

			// if (user.applicationCompleted) {
			if (currentStatus === APPLICATION_STATUS.APPROVED) {
				approved.push(rData);
				allSubmitted.push(rData);
			} else if (
				currentStatus === APPLICATION_STATUS.CONDITIONALLY_APROVED
			) {
				conditionallyApproved.push(rData);
				allSubmitted.push(rData);
			} else if (currentStatus === APPLICATION_STATUS.REJECTED) {
				rejected.push(rData);
				allSubmitted.push(rData);
			} else if (
				currentStatus === APPLICATION_STATUS.MORE_INFO_REQUIRED
			) {
				moreInfo.push(rData);
				allSubmitted.push(rData);
			} else if (currentStatus === APPLICATION_STATUS.PAUSED) {
				paused.push(rData);
				allSubmitted.push(rData);
			} else if (currentStatus === APPLICATION_STATUS.IN_REVIEW) {
				inReview.push(rData);
				allSubmitted.push(rData);
			} else if (currentStatus === APPLICATION_STATUS.OFFER_EXTENDED) {
				offerExtended.push(rData);
				allSubmitted.push(rData);
			} else if (currentStatus === APPLICATION_STATUS.OFFER_ACCEPTED) {
				offerAccepted.push(rData);
				allSubmitted.push(rData);
			} else if (
				currentStatus === APPLICATION_STATUS.INSPECTION_COMPLETE
			) {
				inspectionComplete.push(rData);
				allSubmitted.push(rData);
			} else if (
				currentStatus === APPLICATION_STATUS.PRECLOSING_COMPLETE
			) {
				preclosingComplete.push(rData);
				allSubmitted.push(rData);
			} else if (currentStatus === APPLICATION_STATUS.OFFER_CLOSED) {
				offerClosed.push(rData);
				allSubmitted.push(rData);
			} else if (currentStatus === APPLICATION_STATUS.INACTIVE) {
				inactive.push(rData);
				allSubmitted.push(rData);
			} else if (!user.applicationCompleted && otheredStatus) {
				othered.push(rData);
			} else if (!user.applicationCompleted) {
				started.push(rData);
			} else {
				pending.push(rData);
				allSubmitted.push(rData);
			}

			// allSubmitted.push(rData);
			// } else {
			//   started.push(rData);
			// }

			all.push(rData);

			return rData;
		});

		all = all.sort((a, b) => {
			return b.submissionDate - a.submissionDate;
		});

		approved = approved.sort((a, b) => {
			return b.submissionDate - a.submissionDate;
		});

		conditionallyApproved = conditionallyApproved.sort((a, b) => {
			return b.submissionDate - a.submissionDate;
		});

		allSubmitted = allSubmitted.sort((a, b) => {
			return b.submissionDate - a.submissionDate;
		});

		rejected = rejected.sort((a, b) => {
			return b.submissionDate - a.submissionDate;
		});

		moreInfo = moreInfo.sort((a, b) => {
			return b.submissionDate - a.submissionDate;
		});

		paused = paused.sort((a, b) => {
			return b.submissionDate - a.submissionDate;
		});

		inReview = inReview.sort((a, b) => {
			return b.submissionDate - a.submissionDate;
		});

		offerExtended = offerExtended.sort((a, b) => {
			return b.submissionDate - a.submissionDate;
		});

		offerAccepted = offerAccepted.sort((a, b) => {
			return b.submissionDate - a.submissionDate;
		});

		inspectionComplete = inspectionComplete.sort((a, b) => {
			return b.submissionDate - a.submissionDate;
		});

		preclosingComplete = preclosingComplete.sort((a, b) => {
			return b.submissionDate - a.submissionDate;
		});

		offerClosed = offerClosed.sort((a, b) => {
			return b.submissionDate - a.submissionDate;
		});

		started = started.sort((a, b) => {
			return b.applicationStartData - a.applicationStartData;
		});

		othered = othered.sort((a, b) => {
			return b.applicationStartData - a.applicationStartData;
		});

		inactive = inactive.sort((a, b) => {
			return b.applicationStartData - a.applicationStartData;
		});

		// const { data, error } = await supabase
		// 	.from('waitlist_users')
		// 	.select();
		// if (data.length > 0) {
		// 	waitlistUsers = data;
		// }

		// const [allLandlords, propertyApplications] = await Promise.all([
		// 	getAllLandlords(),
		// 	getLandlordPropertiesApplications(),
		// ]);
		// landlords = allLandlords;
		// properties = propertyApplications?.all;
		// inReviewProperties = propertyApplications?.inReview;
		// rejectedProperties = propertyApplications?.rejected;
		// acceptedProperties = propertyApplications?.accepted;

		// initiatedProperties = await getInitiatedProperties();

		store.dispatch(
			setUsers({
				approved,
				pending,
				rejected,
				all,
				moreInfo,
				started,
				othered,
				allSubmitted,
				inReview,
				paused,
				conditionallyApproved,
				offerExtended,
				offerAccepted,
				inspectionComplete,
				preclosingComplete,
				offerClosed,
				inactive,
				waitlistUsers,
				// landlords,
				// properties,
				// inReviewProperties,
				// rejectedProperties,
				// acceptedProperties,
				// initiatedProperties,
			})
		);

		return allData;
	} catch (err) {
		console.log('Error getAllApplicationsData(): ', err);
		return [];
	}
};

const getLandlordandPropertyData = async (applicationId) => {
	console.log('Running getLandlordandPropertyData()');
	try {
		let landlords = [],
			properties = [],
			inReviewProperties = [],
			rejectedProperties = [],
			acceptedProperties = [],
			initiatedProperties = [];

		const [allLandlords, propertyApplications, initiatedPropertiesData] =
			await Promise.all([
				getAllLandlords(),
				getLandlordPropertiesApplications(),
				getInitiatedProperties(),
			]);
		landlords = allLandlords;
		properties = propertyApplications?.all;
		inReviewProperties = propertyApplications?.inReview;
		rejectedProperties = propertyApplications?.rejected;
		acceptedProperties = propertyApplications?.accepted;
		initiatedProperties = initiatedPropertiesData;

		store.dispatch(
			setLandlords({
				landlords,
				properties,
				inReviewProperties,
				rejectedProperties,
				acceptedProperties,
				initiatedProperties,
			})
		);
	} catch (err) {
		console.log('Error getLandlordandPropertyData(): ', err);
		return null;
	}
};

const getAllApplications = async () => {
	console.log('Running getAllApplications()');
	try {
		const [userSnap, homebuyerSnap] = await Promise.all([
			getDocs(collection(db, 'application_responses')),
			getDocs(collection(db, 'homebuyer_application_responses')),
		]);

		const applications = [
			...userSnap.docs.map((doc) => doc.data()),
			...homebuyerSnap.docs.map((doc) => doc.data()),
		];

		return applications;
	} catch (err) {
		console.log('Error getAllApplications(): ', err);
		return [];
	}
};

const getAllApplications_Optimized = async () => {
	console.log('Running getAllApplications_Optimized()');
	try {
		let {
			applicationResponses: applicationResponsesRaw,
			homebuyerApplicationResponses: homebuyerApplicationResponsesRaw,
		} = store.getState()?.data;

		const applications = [
			...applicationResponsesRaw,
			...homebuyerApplicationResponsesRaw,
		];

		return applications;
	} catch (err) {
		console.log('Error getAllApplications_Optimized(): ', err);
		return [];
	}
};

const getApplicationByUserId = async (applicationId) => {
	console.log('Running getApplicationByUserId()');
	try {
		const appRef = doc(db, 'application_responses', applicationId);
		const docSnap = await getDoc(appRef);
		return docSnap.data();
	} catch (err) {
		console.log('Error getApplicationByUserId(): ', err);
		return null;
	}
};

const getLandlordPropertiesApplications = async () => {
	console.log('Running getLandlordPropertiesApplications()');
	try {
		const landlordProperties = await getDocs(
			collection(db, 'landlord_properties')
		);
		const allPropertiesPromises = landlordProperties.docs.map(
			async (landlordProperty) => {
				const landlordPropertyData = landlordProperty.data();

				const propertyUserId = landlordPropertyData?.userId;
				const propertyUser = await getUserById(propertyUserId);

				return {
					id: landlordProperty.id,
					...landlordPropertyData,
					submissionDate: landlordPropertyData?.timeSubmitted
						? moment
								.tz(
									landlordPropertyData?.timeSubmitted?.toDate(),
									'America/New_York'
								)
								.toDate()
						: null,
					userData: propertyUser,
				};
			}
		);
		const allProperties = await Promise.all(allPropertiesPromises);

		const sortedAllProperties = allProperties.sort((a, b) => {
			return b.submissionDate - a.submissionDate;
		});

		const sortedInReviewProperties = sortedAllProperties.filter(
			(property) => property.status === 'IN_REVIEW'
		);
		const sortedRejectedProperties = sortedAllProperties.filter(
			(property) => property.status === 'REJECTED'
		);
		const sortedAcceptedProperties = sortedAllProperties.filter(
			(property) => property.status === 'ACCEPTED'
		);

		let sortByUsers = [];

		const userPropertiesMap = {};
		sortedAllProperties.forEach((property) => {
			const userId = property.userData?.user_id;
			if (!userPropertiesMap[userId]) {
				userPropertiesMap[userId] = {
					userData: property.userData,
					properties: [],
				};
			}
			userPropertiesMap[userId].properties.push(property);
		});

		// Convert map to array and sort by accountCreated
		sortByUsers = Object.values(userPropertiesMap).sort((a, b) => {
			const aTime = a.userData?.accountCreated?.seconds || 0;
			const bTime = b.userData?.accountCreated?.seconds || 0;
			return bTime - aTime; // Sort descending (most recent first)
		});

		for (let i = 0; i < sortByUsers.length; i++) {
			const currentUser = sortByUsers[i];

			let userApplication = await getLandlordDataById(
				currentUser.userData?.id
			);
			if (userApplication) {
				userApplication.applicationCompletedTime =
					userApplication?.timeSubmitted
						? moment
								.unix(userApplication.timeSubmitted.seconds)
								.tz('America/New_York')
								.format('DD MMM YYYY, hh:mm a')
						: null;
			} else {
				userApplication = {
					applicationCompletedTime: null,
					...userApplication,
				};
			}

			sortByUsers[i] = {
				...currentUser,
				userData: {
					...currentUser.userData,
					application: userApplication,
				},
			};
		}

		return {
			all: sortedAllProperties,
			inReview: sortedInReviewProperties,
			rejected: sortedRejectedProperties,
			accepted: sortedAcceptedProperties,
			sortByUsers,
		};
	} catch (err) {
		console.log('Error getLandlordPropertiesApplications(): ', err);
		return {
			all: [],
			inReview: [],
			rejected: [],
			accepted: [],
			sortByUsers: [],
		};
	}
};

const updatePropertyApplicationDetails = async (
	propertyId,
	propertyApplicationDetails
) => {
	console.log('Running updatePropertyApplicationDetails()');
	try {
		const propertyRef = doc(db, 'landlord_properties', propertyId);
		await updateDoc(propertyRef, {
			status: propertyApplicationDetails?.propertyStatus,
			stepOne: [
				{
					question: propertyApplicationDetails?.stepOne[0]?.question,
					answer: propertyApplicationDetails?.stepOne[0]?.answer,
				},
				{
					question: propertyApplicationDetails?.stepOne[1]?.question,
					answer: propertyApplicationDetails?.stepOne[1]?.answer,
				},
				{
					question: propertyApplicationDetails?.stepOne[2]?.question,
					answer: propertyApplicationDetails?.stepOne[2]?.answer,
				},
				{
					question: propertyApplicationDetails?.stepOne[3]?.question,
					answer: propertyApplicationDetails?.stepOne[3]?.answer,
				},
				{
					question: propertyApplicationDetails?.stepOne[4]?.question,
					answer: propertyApplicationDetails?.stepOne[4]?.answer,
				},
				{
					question: propertyApplicationDetails?.stepOne[5]?.question,
					answer: propertyApplicationDetails?.stepOne[5]?.answer,
				},
				{
					question: propertyApplicationDetails?.stepOne[6]?.question,
					answer: propertyApplicationDetails?.stepOne[6]?.answer,
				},
			],
			stepTwo: [
				{
					question: propertyApplicationDetails?.stepTwo[0]?.question,
					answer: propertyApplicationDetails?.stepTwo[0]?.answer,
				},
				{
					question: propertyApplicationDetails?.stepTwo[1]?.question,
					answer: propertyApplicationDetails?.stepTwo[1]?.answer,
				},
				{
					question: propertyApplicationDetails?.stepTwo[2]?.question,
					answer: propertyApplicationDetails?.stepTwo[2]?.answer,
				},
				{
					question: propertyApplicationDetails?.stepTwo[3]?.question,
					answer: propertyApplicationDetails?.stepTwo[3]?.answer,
				},
			],
			stepThree: [
				{
					question:
						propertyApplicationDetails?.stepThree[0]?.question,
					answer: propertyApplicationDetails?.stepThree[0]?.answer,
				},
				{
					question:
						propertyApplicationDetails?.stepThree[1]?.question,
					answer: propertyApplicationDetails?.stepThree[1]?.answer,
				},
			],
			stepFour: [
				{
					question: propertyApplicationDetails?.stepFour[0]?.question,
					answer: propertyApplicationDetails?.stepFour[0]?.answer,
				},
			],
			stepSeven: [
				{
					question:
						propertyApplicationDetails?.stepSeven[0]?.question,
					answer: propertyApplicationDetails?.stepSeven[0]?.answer,
				},
				{
					question:
						propertyApplicationDetails?.stepSeven[1]?.question,
					answer: propertyApplicationDetails?.stepSeven[1]?.answer,
				},
				{
					question:
						propertyApplicationDetails?.stepSeven[2]?.question,
					answer: propertyApplicationDetails?.stepSeven[2]?.answer,
				},
				{
					question:
						propertyApplicationDetails?.stepSeven[3]?.question,
					answer: propertyApplicationDetails?.stepSeven[3]?.answer,
				},
			],
		});
	} catch (err) {
		console.log('Error updatePropertyApplicationDetails(): ', err);
		return null;
	}
};

const getHomebuyerFilesById = async (userId) => {
	console.log('Running getHomebuyerFilesById()');
	try {
		let incomeVerification = [],
			backgroundVerification = [],
			bankStatements = [];

		const verifyRef = ref(
			storage,
			`homebuyer_application_files/${userId}/income_verification`
		);

		const incomeVerificationRes = await listAll(verifyRef);

		for (let item of incomeVerificationRes.items) {
			let metadata = await getMetadata(item);
			let url = await getDownloadURL(item);
			incomeVerification.push({
				url,
				type: metadata.contentType,
				name: metadata.name,
				path: `homebuyer_application_files/${userId}/income_verification/${metadata.name}`,
				...metadata,
			});
		}

		const backgroundRef = ref(
			storage,
			`homebuyer_application_files/${userId}/background_check`
		);

		const backgroundVerificationRes = await listAll(backgroundRef);

		for (let bgItem of backgroundVerificationRes.items) {
			let metadata = await getMetadata(bgItem);
			let url = await getDownloadURL(bgItem);
			backgroundVerification.push({
				url,
				type: metadata.contentType,
				name: metadata.name,
				path: `homebuyer_application_files/${userId}/income_verification/${metadata.name}`,
				...metadata,
			});
		}

		const bankStatementRef = ref(
			storage,
			`homebuyer_application_files/${userId}/bank_statement`
		);
		const bankStatementRes = await listAll(bankStatementRef);

		for (let statement of bankStatementRes.items) {
			let metadata = await getMetadata(statement);
			let url = await getDownloadURL(statement);
			bankStatements.push({
				url,
				type: metadata.contentType,
				name: metadata.name,
				path: `homebuyer_application_files/${userId}/income_verification/${metadata.name}`,
				...metadata,
			});
		}

		return {
			incomeVerification,
			backgroundVerification,
			bankStatements,
		};
	} catch (err) {
		console.log('Error getHomebuyerFilesById(): ', err);

		const incomeVerification = [];
		const backgroundVerification = [];
		const bankStatements = [];
		return {
			incomeVerification,
			backgroundVerification,
			bankStatements,
		};
	}
};

const getFileDetails = async (path) => {
	console.log('Running getFileDetails()');
	try {
		const fileRef = ref(storage, path);
		const fileRes = await listAll(fileRef);
		return Promise.all(
			fileRes.items.map(async (item) => {
				const metadata = await getMetadata(item);
				const url = await getDownloadURL(item);
				return {
					url,
					type: metadata.contentType,
					name: metadata.name,
					path: item.fullPath,
					...metadata,
				};
			})
		);
	} catch (err) {
		console.log('Error getFileDetails(): ', err);
		return [];
	}
};

const getUserFilesById = async (userId) => {
	console.log('Running getUserFilesById()');
	try {
		const [incomeVerification, backgroundVerification, bankStatements] =
			await Promise.all([
				getFileDetails(
					`application_files/${userId}/income_verification`
				),
				getFileDetails(`application_files/${userId}/background_check`),
				getFileDetails(`application_files/${userId}/bank_statement`),
			]);

		return {
			incomeVerification,
			backgroundVerification,
			bankStatements,
		};
	} catch (err) {
		console.log('Error getUserFilesById(): ', err);

		const incomeVerification = null;
		const backgroundVerification = null;
		const bankStatements = null;

		return {
			incomeVerification,
			backgroundVerification,
			bankStatements,
		};
	}
};

const getUserById = async (userId) => {
	console.log('Running getUserById()');
	try {
		const docRef = doc(db, 'users', userId);
		const docSnap = await getDoc(docRef);
		return { id: userId, ...docSnap.data() };
	} catch (err) {
		console.log('Error getUserById(): ', err);
		return null;
	}
};

const getUserByDisplayId = async (userId) => {
	console.log('Running getUserByDisplayId()');
	try {
		const docRef = doc(db, 'users', {
			user_id: userId,
		});
		const docSnap = await getDoc(docRef);
		return docSnap.data();
	} catch (err) {
		console.log('Error getUserByDisplayId(): ', err);
		return null;
	}
};

const downloadFile = async (filePath) => {
	console.log('Running downloadFile()');
	try {
		const url = await getDownloadURL(ref(storage, filePath));

		const xhr = new XMLHttpRequest();
		xhr.responseType = 'blob';
		xhr.onload = (event) => {
			// const blob = xhr.response;
		};
		xhr.open('GET', url);
		xhr.send();

		const img = document.getElementById('myimg');
		img.setAttribute('src', url);
	} catch (err) {
		console.log('Error downloadFile(): ', err);
		return null;
	}
};

const changeUserStatusToDeleted = async (userId) => {
	console.log('Running changeUserStatusToDeleted()');
	try {
		const docRef = doc(db, 'users', userId);
		await setDoc(
			docRef,
			{
				isDeleted: true,
			},
			{
				merge: true,
			}
		);

		await handleGetUsers();

		// await getAllApplicationsData();
	} catch (err) {
		console.log('Error changeUserStatusToDeleted(): ', err);
		return null;
	}
};

const getPieChartStats = async () => {
	console.log('Running getPieChartStats()');
	// try {
	// 	const userRef = collection(db, 'users');
	// 	const q = query(
	// 		userRef,
	// 		where('applicationCompleted', '==', true)
	// 	);
	// 	const querySnapshot = await getCountFromServer(q);
	// 	const notCompletedQuery = query(
	// 		userRef,
	// 		where('applicationCompleted', '==', false)
	// 	);
	// 	const notCompletedSnapshot = await getCountFromServer(
	// 		notCompletedQuery
	// 	);

	// 	const completedCount = querySnapshot.data().count,
	// 		nonCompletedCount = notCompletedSnapshot.data().count;

	// 	const total = completedCount + nonCompletedCount;
	// 	const completedPercentage = (completedCount / total) * 100;
	// 	const nonCompletedPercentage = 100 - completedPercentage;

	// 	return {
	// 		order: ['completed', 'not completed'],
	// 		percentage: [
	// 			completedPercentage.toFixed(2),
	// 			nonCompletedPercentage.toFixed(2),
	// 		],
	// 		data: [completedCount, nonCompletedCount],
	// 		total,
	// 	};
	// } catch (err) {
	// 	console.log('Error getPieChartStats(): ', err);
	// 	return {
	// 		order: ['completed', 'not completed'],
	// 		percentage: [0, 0],
	// 		data: [0, 0],
	// 		total: 0,
	// 	};
	// }
	try {
		const users = await getAllUsers_Optimized();
		const applications = await getAllApplications_Optimized();

		let pending = [],
			rejected = [],
			approved = [],
			all = [],
			moreInfo = [],
			started = [],
			othered = [],
			allSubmitted = [],
			paused = [],
			inReview = [],
			conditionallyApproved = [],
			offerExtended = [],
			offerAccepted = [],
			inspectionComplete = [],
			preclosingComplete = [],
			offerClosed = [],
			inactive = [];

		const allData = users.map((user) => {
			const application = applications.find(
				(application) => application.uid === user.id
			);

			const appStepSeven = application?.stepSeven;
			const username = user.legalName
				? user?.legalName?.firstName + ' ' + user?.legalName?.lastName
				: appStepSeven
				? appStepSeven[0]?.answer +
				  ' ' +
				  appStepSeven[1].answer +
				  ' ' +
				  appStepSeven[2].answer
				: '';

			const firstName =
				user?.legalName?.firstName ?? appStepSeven
					? appStepSeven[0]?.answer
					: '';

			const lastName =
				user?.legalName?.firstName ?? appStepSeven
					? appStepSeven[2]?.answer
					: '';

			const rData = {
				...user,
				name: username,
				firstName,
				lastName,
				application: application ? application : null,
				preQualifiedAmount: application?.stepEight
					? application?.stepEight[0]?.answer
					: 0,
				qualifiedAmount: convertCommaSeperatedToNormalNumber(
					Number(user?.qualifiedAmount)
				),
				submissionDate: application?.timeSubmitted
					? moment
							.tz(
								application?.timeSubmitted?.toDate(),
								'America/New_York'
							)
							.toDate()
					: null,
			};

			const currentStatus = rData.application?.status;

			const otheredAnswerList = rData.application?.stepThree
				? rData.application?.stepThree
				: [];
			const otheredAnswer = otheredAnswerList[2]?.answer
				? otheredAnswerList[2]?.answer
				: '';
			const otheredStatus = otheredAnswer.includes('Other');

			// if (user.applicationCompleted) {
			if (currentStatus === APPLICATION_STATUS.APPROVED) {
				approved.push(rData);
				allSubmitted.push(rData);
			} else if (
				currentStatus === APPLICATION_STATUS.CONDITIONALLY_APROVED
			) {
				conditionallyApproved.push(rData);
				allSubmitted.push(rData);
			} else if (currentStatus === APPLICATION_STATUS.REJECTED) {
				rejected.push(rData);
				allSubmitted.push(rData);
			} else if (
				currentStatus === APPLICATION_STATUS.MORE_INFO_REQUIRED
			) {
				moreInfo.push(rData);
				allSubmitted.push(rData);
			} else if (currentStatus === APPLICATION_STATUS.PAUSED) {
				paused.push(rData);
				allSubmitted.push(rData);
			} else if (currentStatus === APPLICATION_STATUS.IN_REVIEW) {
				inReview.push(rData);
				allSubmitted.push(rData);
			} else if (currentStatus === APPLICATION_STATUS.OFFER_EXTENDED) {
				offerExtended.push(rData);
				allSubmitted.push(rData);
			} else if (currentStatus === APPLICATION_STATUS.OFFER_ACCEPTED) {
				offerAccepted.push(rData);
				allSubmitted.push(rData);
			} else if (
				currentStatus === APPLICATION_STATUS.INSPECTION_COMPLETE
			) {
				inspectionComplete.push(rData);
				allSubmitted.push(rData);
			} else if (
				currentStatus === APPLICATION_STATUS.PRECLOSING_COMPLETE
			) {
				preclosingComplete.push(rData);
				allSubmitted.push(rData);
			} else if (currentStatus === APPLICATION_STATUS.OFFER_CLOSED) {
				offerClosed.push(rData);
				allSubmitted.push(rData);
			} else if (currentStatus === APPLICATION_STATUS.INACTIVE) {
				inactive.push(rData);
				allSubmitted.push(rData);
			} else if (!user.applicationCompleted && otheredStatus) {
				othered.push(rData);
			} else if (!user.applicationCompleted) {
				started.push(rData);
			} else {
				pending.push(rData);
				allSubmitted.push(rData);
			}

			// allSubmitted.push(rData);
			// } else {
			//   started.push(rData);
			// }

			all.push(rData);

			return rData;
		});

		all = all.sort((a, b) => {
			return b.submissionDate - a.submissionDate;
		});

		started = started.sort((a, b) => {
			return b.applicationStartData - a.applicationStartData;
		});

		const completedCount = allSubmitted.length;
		const startedCount = started.length;
		const totalCount = completedCount + startedCount;

		const completedPercentage = parseFloat(
			((completedCount / totalCount) * 100).toFixed(2)
		);
		const startedPercentage = parseFloat(
			(100 - completedPercentage).toFixed(2)
		);

		return {
			order: ['completed', 'not completed'],
			percentage: [completedPercentage, startedPercentage],
			data: [completedCount, startedCount],
			total: totalCount,
		};
	} catch (err) {
		console.log('Error getPieChartStats(): ', err);
		return {
			order: ['completed', 'not completed'],
			percentage: [0, 0],
			data: [0, 0],
			total: 0,
		};
	}
};

const getPieChartStatsByDateRange = async () => {
	console.log('Running getPieChartStatsByDateRange()');
	try {
		let { fromDate, toDate } = store.getState()?.graph;

		const users = await getAllUsers_Optimized();
		const applications = await getAllApplications_Optimized();

		let startDate = dayjs(fromDate).tz(tz, true).toDate();
		let endDate = dayjs(toDate).tz(tz, true).toDate();

		let pending = [],
			rejected = [],
			approved = [],
			all = [],
			moreInfo = [],
			started = [],
			othered = [],
			allSubmitted = [],
			paused = [],
			inReview = [],
			conditionallyApproved = [],
			offerExtended = [],
			offerAccepted = [],
			inspectionComplete = [],
			preclosingComplete = [],
			offerClosed = [],
			inactive = [];

		const allData = users.map((user) => {
			const application = applications.find(
				(application) => application.uid === user.id
			);

			const appStepSeven = application?.stepSeven;
			const username = user.legalName
				? user?.legalName?.firstName + ' ' + user?.legalName?.lastName
				: appStepSeven
				? appStepSeven[0]?.answer +
				  ' ' +
				  appStepSeven[1].answer +
				  ' ' +
				  appStepSeven[2].answer
				: '';

			const firstName =
				user?.legalName?.firstName ?? appStepSeven
					? appStepSeven[0]?.answer
					: '';

			const lastName =
				user?.legalName?.firstName ?? appStepSeven
					? appStepSeven[2]?.answer
					: '';

			const rData = {
				...user,
				name: username,
				firstName,
				lastName,
				application: application ? application : null,
				preQualifiedAmount: application?.stepEight
					? application?.stepEight[0]?.answer
					: 0,
				qualifiedAmount: convertCommaSeperatedToNormalNumber(
					Number(user?.qualifiedAmount)
				),
				submissionDate: application?.timeSubmitted
					? moment
							.tz(
								application?.timeSubmitted?.toDate(),
								'America/New_York'
							)
							.toDate()
					: null,
			};

			const currentStatus = rData.application?.status;

			const otheredAnswerList = rData.application?.stepThree
				? rData.application?.stepThree
				: [];
			const otheredAnswer = otheredAnswerList[2]?.answer
				? otheredAnswerList[2]?.answer
				: '';
			const otheredStatus = otheredAnswer.includes('Other');

			// if (user.applicationCompleted) {
			if (currentStatus === APPLICATION_STATUS.APPROVED) {
				approved.push(rData);
				allSubmitted.push(rData);
			} else if (
				currentStatus === APPLICATION_STATUS.CONDITIONALLY_APROVED
			) {
				conditionallyApproved.push(rData);
				allSubmitted.push(rData);
			} else if (currentStatus === APPLICATION_STATUS.REJECTED) {
				rejected.push(rData);
				allSubmitted.push(rData);
			} else if (
				currentStatus === APPLICATION_STATUS.MORE_INFO_REQUIRED
			) {
				moreInfo.push(rData);
				allSubmitted.push(rData);
			} else if (currentStatus === APPLICATION_STATUS.PAUSED) {
				paused.push(rData);
				allSubmitted.push(rData);
			} else if (currentStatus === APPLICATION_STATUS.IN_REVIEW) {
				inReview.push(rData);
				allSubmitted.push(rData);
			} else if (currentStatus === APPLICATION_STATUS.OFFER_EXTENDED) {
				offerExtended.push(rData);
				allSubmitted.push(rData);
			} else if (currentStatus === APPLICATION_STATUS.OFFER_ACCEPTED) {
				offerAccepted.push(rData);
				allSubmitted.push(rData);
			} else if (
				currentStatus === APPLICATION_STATUS.INSPECTION_COMPLETE
			) {
				inspectionComplete.push(rData);
				allSubmitted.push(rData);
			} else if (
				currentStatus === APPLICATION_STATUS.PRECLOSING_COMPLETE
			) {
				preclosingComplete.push(rData);
				allSubmitted.push(rData);
			} else if (currentStatus === APPLICATION_STATUS.OFFER_CLOSED) {
				offerClosed.push(rData);
				allSubmitted.push(rData);
			} else if (currentStatus === APPLICATION_STATUS.INACTIVE) {
				inactive.push(rData);
				allSubmitted.push(rData);
			} else if (!user.applicationCompleted && otheredStatus) {
				othered.push(rData);
			} else if (!user.applicationCompleted) {
				started.push(rData);
			} else {
				pending.push(rData);
				allSubmitted.push(rData);
			}

			// allSubmitted.push(rData);
			// } else {
			//   started.push(rData);
			// }

			all.push(rData);

			return rData;
		});

		all = all.sort((a, b) => {
			return b.submissionDate - a.submissionDate;
		});

		approved = approved.sort((a, b) => {
			return b.submissionDate - a.submissionDate;
		});

		conditionallyApproved = conditionallyApproved.sort((a, b) => {
			return b.submissionDate - a.submissionDate;
		});

		allSubmitted = allSubmitted.sort((a, b) => {
			return b.submissionDate - a.submissionDate;
		});

		rejected = rejected.sort((a, b) => {
			return b.submissionDate - a.submissionDate;
		});

		moreInfo = moreInfo.sort((a, b) => {
			return b.submissionDate - a.submissionDate;
		});

		paused = paused.sort((a, b) => {
			return b.submissionDate - a.submissionDate;
		});

		inReview = inReview.sort((a, b) => {
			return b.submissionDate - a.submissionDate;
		});

		offerExtended = offerExtended.sort((a, b) => {
			return b.submissionDate - a.submissionDate;
		});

		offerAccepted = offerAccepted.sort((a, b) => {
			return b.submissionDate - a.submissionDate;
		});

		inspectionComplete = inspectionComplete.sort((a, b) => {
			return b.submissionDate - a.submissionDate;
		});

		preclosingComplete = preclosingComplete.sort((a, b) => {
			return b.submissionDate - a.submissionDate;
		});

		offerClosed = offerClosed.sort((a, b) => {
			return b.submissionDate - a.submissionDate;
		});

		started = started.sort((a, b) => {
			return b.applicationStartData - a.applicationStartData;
		});

		othered = othered.sort((a, b) => {
			return b.applicationStartData - a.applicationStartData;
		});

		inactive = inactive.sort((a, b) => {
			return b.applicationStartData - a.applicationStartData;
		});

		let dateRange_pending = [],
			dateRange_rejected = [],
			dateRange_approved = [],
			dateRange_all = [],
			dateRange_moreInfo = [],
			dateRange_started = [],
			dateRange_othered = [],
			dateRange_allSubmitted = [],
			dateRange_paused = [],
			dateRange_inReview = [],
			dateRange_conditionallyApproved = [],
			dateRange_offerExtended = [],
			dateRange_offerAccepted = [],
			dateRange_inspectionComplete = [],
			dateRange_preclosingComplete = [],
			dateRange_offerClosed = [],
			dateRange_inactive = [];
		while (startDate <= endDate) {
			const numUsersInDateRange = all.filter((user) => {
				return (
					user?.applicationStartDate?.getDate() ===
						startDate.getDate() &&
					user?.applicationStartDate?.getMonth() ===
						startDate.getMonth() &&
					user?.applicationStartDate?.getFullYear() ===
						startDate.getFullYear()
				);
			});
			for (let i = 0; i < numUsersInDateRange.length; i++) {
				dateRange_all.push(numUsersInDateRange[i]);
			}

			const numSubmittedInDateRange = allSubmitted.filter((user) => {
				return (
					user?.applicationStartDate?.getDate() ===
						startDate.getDate() &&
					user?.applicationStartDate?.getMonth() ===
						startDate.getMonth() &&
					user?.applicationStartDate?.getFullYear() ===
						startDate.getFullYear()
				);
			});
			for (let i = 0; i < numSubmittedInDateRange.length; i++) {
				dateRange_allSubmitted.push(numSubmittedInDateRange[i]);
			}

			const numStartedInDateRange = started.filter((user) => {
				return (
					user?.applicationStartDate?.getDate() ===
						startDate.getDate() &&
					user?.applicationStartDate?.getMonth() ===
						startDate.getMonth() &&
					user?.applicationStartDate?.getFullYear() ===
						startDate.getFullYear()
				);
			});
			for (let i = 0; i < numStartedInDateRange.length; i++) {
				dateRange_started.push(numStartedInDateRange[i]);
			}

			const numOtheredInDateRange = othered.filter((user) => {
				return (
					user?.applicationStartDate?.getDate() ===
						startDate.getDate() &&
					user?.applicationStartDate?.getMonth() ===
						startDate.getMonth() &&
					user?.applicationStartDate?.getFullYear() ===
						startDate.getFullYear()
				);
			});
			for (let i = 0; i < numOtheredInDateRange.length; i++) {
				dateRange_othered.push(numOtheredInDateRange[i]);
			}

			const numOffersExtendedDateRange = offerExtended.filter((user) => {
				return (
					user?.applicationStartDate?.getDate() ===
						startDate.getDate() &&
					user?.applicationStartDate?.getMonth() ===
						startDate.getMonth() &&
					user?.applicationStartDate?.getFullYear() ===
						startDate.getFullYear()
				);
			});
			for (let i = 0; i < numOffersExtendedDateRange.length; i++) {
				dateRange_offerExtended.push(numOffersExtendedDateRange[i]);
			}

			const numOffersAcceptedDateRange = offerAccepted.filter((user) => {
				return (
					user?.applicationStartDate?.getDate() ===
						startDate.getDate() &&
					user?.applicationStartDate?.getMonth() ===
						startDate.getMonth() &&
					user?.applicationStartDate?.getFullYear() ===
						startDate.getFullYear()
				);
			});
			for (let i = 0; i < numOffersAcceptedDateRange.length; i++) {
				dateRange_offerAccepted.push(numOffersAcceptedDateRange[i]);
			}

			const numInspectionsCompleteDateRange = inspectionComplete.filter(
				(user) => {
					return (
						user?.applicationStartDate?.getDate() ===
							startDate.getDate() &&
						user?.applicationStartDate?.getMonth() ===
							startDate.getMonth() &&
						user?.applicationStartDate?.getFullYear() ===
							startDate.getFullYear()
					);
				}
			);
			for (let i = 0; i < numInspectionsCompleteDateRange.length; i++) {
				dateRange_inspectionComplete.push(
					numInspectionsCompleteDateRange[i]
				);
			}

			const numPreclosingCompleteDateRange = preclosingComplete.filter(
				(user) => {
					return (
						user?.applicationStartDate?.getDate() ===
							startDate.getDate() &&
						user?.applicationStartDate?.getMonth() ===
							startDate.getMonth() &&
						user?.applicationStartDate?.getFullYear() ===
							startDate.getFullYear()
					);
				}
			);
			for (let i = 0; i < numPreclosingCompleteDateRange.length; i++) {
				dateRange_preclosingComplete.push(
					numPreclosingCompleteDateRange[i]
				);
			}

			const numOfferClosedDateRange = offerClosed.filter((user) => {
				return (
					user?.applicationStartDate?.getDate() ===
						startDate.getDate() &&
					user?.applicationStartDate?.getMonth() ===
						startDate.getMonth() &&
					user?.applicationStartDate?.getFullYear() ===
						startDate.getFullYear()
				);
			});
			for (let i = 0; i < numOfferClosedDateRange.length; i++) {
				dateRange_offerClosed.push(numOfferClosedDateRange[i]);
			}

			startDate.setDate(startDate.getDate() + 1);
		}

		store.dispatch(
			setDateRangeUsers({
				approved: [0],
				pending: [0],
				rejected: [0],
				all: dateRange_all,
				moreInfo: [0],
				started: dateRange_started,
				othered: dateRange_othered,
				allSubmitted: dateRange_allSubmitted,
				inReview: [0],
				paused: [0],
				conditionallyApproved: [0],
				offerExtended: dateRange_offerExtended,
				offerAccepted: dateRange_offerAccepted,
				inspectionComplete: dateRange_inspectionComplete,
				preclosingComplete: dateRange_preclosingComplete,
				offerClosed: dateRange_offerClosed,
				inactive: [0],
			})
		);
	} catch (err) {
		console.log('Error getPieChartStatsByDateRange(): ', err);
		return null;
	}
};

const getGraphsDataBasedOnDateRange = async (callback) => {
	console.log('Running getGraphsDataBasedOnDateRange()');
	try {
		let { fromDate, toDate } = store.getState()?.graph;

		if (!fromDate && !toDate) {
			let d = new Date();
			d.setDate(d.getDate() - 7);

			fromDate = d;
			toDate = new Date();

			store.dispatch(setFromDate(fromDate));
			store.dispatch(setToDate(toDate));
		}

		const users = await getAllUsers_Optimized();
		const applications = await getAllApplications_Optimized();

		let startDate = dayjs(fromDate).tz(tz, true).toDate();
		let endDate = dayjs(toDate).tz(tz, true).toDate();

		let pending = [],
			rejected = [],
			approved = [],
			all = [],
			moreInfo = [],
			started = [],
			othered = [],
			allSubmitted = [],
			paused = [],
			inReview = [],
			conditionallyApproved = [],
			offerExtended = [],
			offerAccepted = [],
			inspectionComplete = [],
			preclosingComplete = [],
			offerClosed = [],
			inactive = [];

		const allData = users.map((user) => {
			const application = applications.find(
				(application) => application.uid === user.id
			);

			const appStepSeven = application?.stepSeven;
			const username = user.legalName
				? user?.legalName?.firstName + ' ' + user?.legalName?.lastName
				: appStepSeven
				? appStepSeven[0]?.answer +
				  ' ' +
				  appStepSeven[1].answer +
				  ' ' +
				  appStepSeven[2].answer
				: '';

			const firstName =
				user?.legalName?.firstName ?? appStepSeven
					? appStepSeven[0]?.answer
					: '';

			const lastName =
				user?.legalName?.firstName ?? appStepSeven
					? appStepSeven[2]?.answer
					: '';

			const rData = {
				...user,
				name: username,
				firstName,
				lastName,
				application: application ? application : null,
				preQualifiedAmount: application?.stepEight
					? application?.stepEight[0]?.answer
					: 0,
				qualifiedAmount: convertCommaSeperatedToNormalNumber(
					Number(user?.qualifiedAmount)
				),
				submissionDate: application?.timeSubmitted
					? moment
							.tz(
								application?.timeSubmitted?.toDate(),
								'America/New_York'
							)
							.toDate()
					: null,
			};

			const currentStatus = rData.application?.status;

			const otheredAnswerList = rData.application?.stepThree
				? rData.application?.stepThree
				: [];
			const otheredAnswer = otheredAnswerList[2]?.answer
				? otheredAnswerList[2]?.answer
				: '';
			const otheredStatus = otheredAnswer.includes('Other');

			// if (user.applicationCompleted) {
			if (currentStatus === APPLICATION_STATUS.APPROVED) {
				approved.push(rData);
				allSubmitted.push(rData);
			} else if (
				currentStatus === APPLICATION_STATUS.CONDITIONALLY_APROVED
			) {
				conditionallyApproved.push(rData);
				allSubmitted.push(rData);
			} else if (currentStatus === APPLICATION_STATUS.REJECTED) {
				rejected.push(rData);
				allSubmitted.push(rData);
			} else if (
				currentStatus === APPLICATION_STATUS.MORE_INFO_REQUIRED
			) {
				moreInfo.push(rData);
				allSubmitted.push(rData);
			} else if (currentStatus === APPLICATION_STATUS.PAUSED) {
				paused.push(rData);
				allSubmitted.push(rData);
			} else if (currentStatus === APPLICATION_STATUS.IN_REVIEW) {
				inReview.push(rData);
				allSubmitted.push(rData);
			} else if (currentStatus === APPLICATION_STATUS.OFFER_EXTENDED) {
				offerExtended.push(rData);
				allSubmitted.push(rData);
			} else if (currentStatus === APPLICATION_STATUS.OFFER_ACCEPTED) {
				offerAccepted.push(rData);
				allSubmitted.push(rData);
			} else if (
				currentStatus === APPLICATION_STATUS.INSPECTION_COMPLETE
			) {
				inspectionComplete.push(rData);
				allSubmitted.push(rData);
			} else if (
				currentStatus === APPLICATION_STATUS.PRECLOSING_COMPLETE
			) {
				preclosingComplete.push(rData);
				allSubmitted.push(rData);
			} else if (currentStatus === APPLICATION_STATUS.OFFER_CLOSED) {
				offerClosed.push(rData);
				allSubmitted.push(rData);
			} else if (currentStatus === APPLICATION_STATUS.INACTIVE) {
				inactive.push(rData);
				allSubmitted.push(rData);
			} else if (!user.applicationCompleted && otheredStatus) {
				othered.push(rData);
			} else if (!user.applicationCompleted) {
				started.push(rData);
			} else {
				pending.push(rData);
				allSubmitted.push(rData);
			}

			// allSubmitted.push(rData);
			// } else {
			//   started.push(rData);
			// }

			all.push(rData);

			return rData;
		});

		all = all.sort((a, b) => {
			return b.submissionDate - a.submissionDate;
		});

		approved = approved.sort((a, b) => {
			return b.submissionDate - a.submissionDate;
		});

		conditionallyApproved = conditionallyApproved.sort((a, b) => {
			return b.submissionDate - a.submissionDate;
		});

		allSubmitted = allSubmitted.sort((a, b) => {
			return b.submissionDate - a.submissionDate;
		});

		rejected = rejected.sort((a, b) => {
			return b.submissionDate - a.submissionDate;
		});

		moreInfo = moreInfo.sort((a, b) => {
			return b.submissionDate - a.submissionDate;
		});

		paused = paused.sort((a, b) => {
			return b.submissionDate - a.submissionDate;
		});

		inReview = inReview.sort((a, b) => {
			return b.submissionDate - a.submissionDate;
		});

		offerExtended = offerExtended.sort((a, b) => {
			return b.submissionDate - a.submissionDate;
		});

		offerAccepted = offerAccepted.sort((a, b) => {
			return b.submissionDate - a.submissionDate;
		});

		inspectionComplete = inspectionComplete.sort((a, b) => {
			return b.submissionDate - a.submissionDate;
		});

		preclosingComplete = preclosingComplete.sort((a, b) => {
			return b.submissionDate - a.submissionDate;
		});

		offerClosed = offerClosed.sort((a, b) => {
			return b.submissionDate - a.submissionDate;
		});

		started = started.sort((a, b) => {
			return b.applicationStartData - a.applicationStartData;
		});

		othered = othered.sort((a, b) => {
			return b.applicationStartData - a.applicationStartData;
		});

		inactive = inactive.sort((a, b) => {
			return b.applicationStartData - a.applicationStartData;
		});

		let applicationStartBarChartData = {
			categories: [],
			chartData: [],
		};
		let applicationCompleteBarChartData = {
			categories: [],
			chartData: [],
		};
		while (startDate <= endDate) {
			applicationStartBarChartData.categories.push(
				dayjs(startDate).tz(tz, true).format('MMM DD, YY')
			);
			applicationCompleteBarChartData.categories.push(
				dayjs(startDate).tz(tz, true).format('MMM DD, YY')
			);

			const numSubmittedInDateRange = allSubmitted.filter((user) => {
				return (
					user?.applicationStartDate?.getDate() ===
						startDate.getDate() &&
					user?.applicationStartDate?.getMonth() ===
						startDate.getMonth() &&
					user?.applicationStartDate?.getFullYear() ===
						startDate.getFullYear()
				);
			});
			applicationCompleteBarChartData.chartData.push(
				numSubmittedInDateRange.length
			);

			const numStartedInDateRange = started.filter((user) => {
				return (
					user?.applicationStartDate?.getDate() ===
						startDate.getDate() &&
					user?.applicationStartDate?.getMonth() ===
						startDate.getMonth() &&
					user?.applicationStartDate?.getFullYear() ===
						startDate.getFullYear()
				);
			});
			applicationStartBarChartData.chartData.push(
				numStartedInDateRange.length
			);

			startDate.setDate(startDate.getDate() + 1);
		}

		store.dispatch(
			setApplicationCompletedData(applicationCompleteBarChartData)
		);
		store.dispatch(
			setStartedApplicationBarData(applicationStartBarChartData)
		);
		callback && callback();
	} catch (err) {
		console.log('Error getGraphsDataBasedOnDateRange(): ', err);
		return null;
	}
};

const updateApplicationResponseFields = async (updatedField, applicationId) => {
	console.log('Running updateApplicationResponseFields()');
	try {
		const docRef = doc(
			db,
			'homebuyer_application_responses',
			applicationId
		);
		await setDoc(docRef, updatedField, {
			merge: true,
		});

		const appDocRef = doc(db, 'application_responses', applicationId);
		await setDoc(appDocRef, updatedField, {
			merge: true,
		});

		await handleGetHomebuyerApplicationResponses();
		await handleGetApplicationResponses();
		await getAllApplicationsData();
	} catch (err) {
		console.log('Error updateApplicationResponseFields(): ', err);
		return null;
	}
};

const updateCoApplicationResponseFields = async (
	updatedField,
	applicationId
) => {
	console.log('Running updateCoApplicationResponseFields()');
	try {
		const docRef = doc(
			db,
			'coapplicant_application_responses',
			applicationId
		);
		await setDoc(docRef, updatedField, {
			merge: true,
		});

		// const appDocRef = doc(db, "application_responses", applicationId);
		// await setDoc(appDocRef, updatedField, {
		//   merge: true,
		// });

		await handleGetCoapplicantApplicationResponses();
		await getAllApplicationsData();
	} catch (err) {
		console.log('Error updateCoApplicationResponseFields(): ', err);
		return null;
	}
};

const updateInvitationStatus = async (userEmail, status, name) => {
	console.log('Running updateInvitationStatus()');
	try {
		const clientRef = collection(db, 'agent_invites');
		const q = query(clientRef, where('clientEmail', '==', userEmail));
		const querySnapshot = await getDocs(q);

		querySnapshot.docs.forEach(async (doc) => {
			updateDoc(doc.ref, {
				status: status,
				clientName: name,
			});
		});

		await handleGetAgentInvites();
	} catch (err) {
		console.log('Error updateInvitationStatus(): ', err);
		return null;
	}
};

const updateUserFields = async (updatedField, userId) => {
	console.log('Running updateUserFields()');
	try {
		const docRef = doc(db, 'users', userId);
		await setDoc(docRef, updatedField, {
			merge: true,
		});

		await handleGetUsers();
		// await getAllApplicationsData();
	} catch (err) {
		console.log('Error updateUserFields(): ', err);
		return null;
	}
};

const getMonthlyStats = async (year = new Date().getFullYear()) => {
	console.log('Running getMonthlyStats()');
	try {
		const users = await getAllUsers_Optimized();
		const applications = await getAllApplications_Optimized();

		let startDate = new Date(year, 0, 1);
		let endDate = new Date(year, 11, 31);

		let categories = [];

		let appStartData = [],
			applicationsCompletedData = [],
			offersExtendedData = [],
			offersAcceptedData = [],
			inspectionCompleteData = [],
			preclosingCompleteData = [],
			offerClosedData = [];

		while (startDate <= endDate) {
			const month = startDate.toLocaleString('default', {
				month: 'long',
			});

			const numUsersInCurrentMonth = users.filter((user) => {
				return (
					user?.applicationStartDate?.getMonth() ===
						startDate.getMonth() &&
					user?.applicationStartDate?.getFullYear() ===
						startDate.getFullYear()
				);
			});
			const numApplicationsInCurrentMonth = applications.filter(
				(application) =>
					new Date(
						application?.timeSubmitted?.toDate()
					)?.getMonth() === startDate.getMonth() &&
					new Date(
						application?.timeSubmitted?.toDate()
					)?.getFullYear() === startDate.getFullYear()
			);

			const numOffersExtendedInCurrentMonth = applications.filter(
				(application) =>
					new Date(
						application?.timeSubmitted?.toDate()
					)?.getMonth() === startDate.getMonth() &&
					new Date(
						application?.timeSubmitted?.toDate()
					)?.getFullYear() === startDate.getFullYear() &&
					application.status === 'offerExtended'
			);

			const numOffersAcceptedInCurrentMonth = applications.filter(
				(application) =>
					new Date(
						application?.timeSubmitted?.toDate()
					)?.getMonth() === startDate.getMonth() &&
					new Date(
						application?.timeSubmitted?.toDate()
					)?.getFullYear() === startDate.getFullYear() &&
					application.status === 'offerAccepted'
			);

			const numInspectionsCompleteInCurrentMonth = applications.filter(
				(application) =>
					new Date(
						application?.timeSubmitted?.toDate()
					)?.getMonth() === startDate.getMonth() &&
					new Date(
						application?.timeSubmitted?.toDate()
					)?.getFullYear() === startDate.getFullYear() &&
					application.status === 'inspectionComplete'
			);

			const numPreclosingCompleteInCurrentMonth = applications.filter(
				(application) =>
					new Date(
						application?.timeSubmitted?.toDate()
					)?.getMonth() === startDate.getMonth() &&
					new Date(
						application?.timeSubmitted?.toDate()
					)?.getFullYear() === startDate.getFullYear() &&
					application.status === 'preclosingComplete'
			);

			const numOffersClosedInCurrentMonth = applications.filter(
				(application) =>
					new Date(
						application?.timeSubmitted?.toDate()
					)?.getMonth() === startDate.getMonth() &&
					new Date(
						application?.timeSubmitted?.toDate()
					)?.getFullYear() === startDate.getFullYear() &&
					application.status === 'offerClosed'
			);

			categories.push(`${month}, ${year.toString().slice(-2)}`);
			appStartData.push(numUsersInCurrentMonth.length);
			applicationsCompletedData.push(
				numApplicationsInCurrentMonth.length
			);
			offersExtendedData.push(numOffersExtendedInCurrentMonth.length);
			offersAcceptedData.push(numOffersAcceptedInCurrentMonth.length);
			inspectionCompleteData.push(
				numInspectionsCompleteInCurrentMonth.length
			);
			preclosingCompleteData.push(
				numPreclosingCompleteInCurrentMonth.length
			);
			offerClosedData.push(numOffersClosedInCurrentMonth.length);

			startDate.setMonth(startDate.getMonth() + 1);
		}

		store.dispatch(
			setMonthlyStats({
				completed: {
					categories,
					chartData: applicationsCompletedData,
				},
				started: {
					categories,
					chartData: appStartData,
				},
				offersExtended: {
					categories,
					chartData: offersExtendedData,
				},
				offersAccepted: {
					categories,
					chartData: offersAcceptedData,
				},
				inspectionComplete: {
					categories,
					chartData: inspectionCompleteData,
				},
				preclosingComplete: {
					categories,
					chartData: preclosingCompleteData,
				},
				offerClosed: {
					categories,
					chartData: offerClosedData,
				},
			})
		);
	} catch (err) {
		console.log('Error getMonthlyStats(): ', err);
		return null;
	}
};

const getMonthlyStats_New = async (year = new Date().getFullYear()) => {
	console.log('Running getMonthlyStats_New()');
	try {
		const users = await getAllApplicationsData_Internal();

		let startDate = new Date(year, 0, 1);
		let endDate = new Date(year, 11, 31);

		let categories = [];

		let appStartData = [],
			applicationsCompletedData = [],
			offersExtendedData = [],
			offersAcceptedData = [],
			inspectionCompleteData = [],
			preclosingCompleteData = [],
			offerClosedData = [];

		while (startDate <= endDate) {
			const month = startDate.toLocaleString('default', {
				month: 'long',
			});

			const numApplicationsStartedInCurrentMonth = users.started.filter(
				(user) => {
					return (
						user?.applicationStartDate?.getMonth() ===
							startDate.getMonth() &&
						user?.applicationStartDate?.getFullYear() ===
							startDate.getFullYear()
					);
				}
			);

			const numApplicationsCompletedInCurrentMonth =
				users.allSubmitted.filter(
					(user) =>
						new Date(
							user?.application?.timeSubmitted?.toDate()
						)?.getMonth() === startDate.getMonth() &&
						new Date(
							user?.application?.timeSubmitted?.toDate()
						)?.getFullYear() === startDate.getFullYear()
				);

			const numOffersExtendedInCurrentMonth = users.offerExtended.filter(
				(user) =>
					new Date(
						user?.application?.timeSubmitted?.toDate()
					)?.getMonth() === startDate.getMonth() &&
					new Date(
						user?.application?.timeSubmitted?.toDate()
					)?.getFullYear() === startDate.getFullYear()
			);

			const numOffersAcceptedInCurrentMonth = users.offerAccepted.filter(
				(user) =>
					new Date(
						user?.application?.timeSubmitted?.toDate()
					)?.getMonth() === startDate.getMonth() &&
					new Date(
						user?.application?.timeSubmitted?.toDate()
					)?.getFullYear() === startDate.getFullYear()
			);

			const numInspectionsCompleteInCurrentMonth =
				users.inspectionComplete.filter(
					(user) =>
						new Date(
							user?.application?.timeSubmitted?.toDate()
						)?.getMonth() === startDate.getMonth() &&
						new Date(
							user?.application?.timeSubmitted?.toDate()
						)?.getFullYear() === startDate.getFullYear()
				);

			const numPreclosingCompleteInCurrentMonth =
				users.preclosingComplete.filter(
					(user) =>
						new Date(
							user?.application?.timeSubmitted?.toDate()
						)?.getMonth() === startDate.getMonth() &&
						new Date(
							user?.application?.timeSubmitted?.toDate()
						)?.getFullYear() === startDate.getFullYear()
				);

			const numOffersClosedInCurrentMonth = users.offerClosed.filter(
				(user) =>
					new Date(
						user?.application?.timeSubmitted?.toDate()
					)?.getMonth() === startDate.getMonth() &&
					new Date(
						user?.application?.timeSubmitted?.toDate()
					)?.getFullYear() === startDate.getFullYear()
			);

			categories.push(`${month}, ${year.toString().slice(-2)}`);
			appStartData.push(numApplicationsStartedInCurrentMonth.length);
			applicationsCompletedData.push(
				numApplicationsCompletedInCurrentMonth.length
			);
			offersExtendedData.push(numOffersExtendedInCurrentMonth.length);
			offersAcceptedData.push(numOffersAcceptedInCurrentMonth.length);
			inspectionCompleteData.push(
				numInspectionsCompleteInCurrentMonth.length
			);
			preclosingCompleteData.push(
				numPreclosingCompleteInCurrentMonth.length
			);
			offerClosedData.push(numOffersClosedInCurrentMonth.length);

			startDate.setMonth(startDate.getMonth() + 1);
		}

		store.dispatch(
			setMonthlyStats({
				completed: {
					categories,
					chartData: applicationsCompletedData,
				},
				started: {
					categories,
					chartData: appStartData,
				},
				offersExtended: {
					categories,
					chartData: offersExtendedData,
				},
				offersAccepted: {
					categories,
					chartData: offersAcceptedData,
				},
				inspectionComplete: {
					categories,
					chartData: inspectionCompleteData,
				},
				preclosingComplete: {
					categories,
					chartData: preclosingCompleteData,
				},
				offerClosed: {
					categories,
					chartData: offerClosedData,
				},
			})
		);
	} catch (err) {
		console.log('Error getMonthlyStats_New(): ', err);
		return null;
	}
};

const getCoapplicantData = async (homebuyerId) => {
	console.log('Running getCoapplicantData()');
	try {
		const userRef = collection(db, 'coapplicant_application_responses');
		const userQuery = query(userRef, where('homebuyer', '==', homebuyerId));
		const querySnapshot = await getDocs(userQuery);

		let res;

		if (querySnapshot.docs.length) {
			res = {
				...querySnapshot.docs[0]?.data(),
				id: querySnapshot.docs[0].id,
			};
		}

		return res;
	} catch (err) {
		console.log('Error getCoapplicantData(): ', err);
		return null;
	}
};

const getCoapplicantData_Optimized = async (homebuyerId) => {
	console.log('Running getCoapplicantData_Optimized()');
	try {
		let {
			coapplicantApplicationResponses: coapplicantApplicationResponsesRaw,
		} = store.getState()?.data;

		let res;
		for (let i = 0; i < coapplicantApplicationResponsesRaw.length; i++) {
			const data = coapplicantApplicationResponsesRaw[i];

			if (homebuyerId === data.homebuyer) {
				res = data;
				break;
			}
		}

		return res;
	} catch (err) {
		console.log('Error getCoapplicantData_Optimized(): ', err);
		return null;
	}
};

const getCoapplicantStorageFiles = async (userId) => {
	console.log('Running getCoapplicantStorageFiles()');
	try {
		const [incomeVerification, backgroundVerification, bankStatements] =
			await Promise.all([
				getFileDetails(
					`coapplicant_application_files/${userId}/income_verification`
				),
				getFileDetails(
					`coapplicant_application_files/${userId}/background_check`
				),
				getFileDetails(
					`coapplicant_application_files/${userId}/bank_statement`
				),
			]);

		return {
			incomeVerification,
			backgroundVerification,
			bankStatements,
		};
	} catch (err) {
		console.log('Error getCoapplicantStorageFiles(): ', err);

		const incomeVerification = null;
		const backgroundVerification = null;
		const bankStatements = null;
		return {
			incomeVerification,
			backgroundVerification,
			bankStatements,
		};
	}
};

const getUserByEmail = async (email) => {
	console.log('Running getUserByEmail()');
	try {
		const userRef = collection(db, 'users');
		const userQuery = query(userRef, where('email', '==', email));
		const querySnapshot = await getDocs(userQuery);

		let res;

		if (querySnapshot.docs.length) {
			res = {
				...querySnapshot.docs[0]?.data(),
				id: querySnapshot.docs[0].id,
			};
		}

		return res;
	} catch (err) {
		console.log('Error getUserByEmail(): ', err);
		return [];
	}
};

const getUserByEmail_Optimized = async (email) => {
	console.log('Running getUserByEmail_Optimized()');
	try {
		let { users: usersRaw } = store.getState()?.data;

		let res;
		for (let i = 0; i < usersRaw.length; i++) {
			const data = usersRaw[i];

			if (email === data.email) {
				res = data;
				break;
			}
		}

		return res;
	} catch (err) {
		console.log('Error getUserByEmail_Optimized(): ', err);
		return [];
	}
};

const getUserApplication = async (userId) => {
	console.log('Running getUserApplication()');
	try {
		const docRef = doc(db, 'homebuyer_application_responses', userId);
		const docSnap = await getDoc(docRef);
		return docSnap.data();
	} catch (err) {
		console.log('Error getUserApplication(): ', err);
		return null;
	}
};

const getUsersWithApplicationsByEmails = async (emails) => {
	console.log('Running getUsersWithApplicationsByEmails()');
	try {
		const userMap = {};

		for (let i = 0; i < emails.length; i += 10) {
			const chunk = emails.slice(i, i + 10);

			const clientRef = collection(db, 'users');
			const q = query(clientRef, where('email', 'in', chunk));
			const querySnapshot = await getDocs(q);

			const userApplicationsPromises = querySnapshot.docs.map(
				async (doc) => {
					const userData = { ...doc.data(), uid: doc.id };
					const application = await getUserApplication(userData.uid);

					userMap[userData.email] = {
						user: userData,
						application,
					};
				}
			);

			await Promise.all(userApplicationsPromises);
		}

		return userMap;
	} catch (err) {
		console.log('Error getUsersWithApplicationsByEmails(): ', err);
		return null;
	}
};

// const getApplicationAdditionalDocuments = async (homebuyerId, coBuyerId) => {
//   let buyerAdditionalDocuments = [],
//     coBuyerAdditionalDocuments = [];

//   const verifyRef = ref(
//     storage,
//     `homebuyer_additional_documents/${homebuyerId}`
//   );

//   const buyerAdditionalDocumentsRes = await listAll(verifyRef);

//   for (let item of buyerAdditionalDocumentsRes.items) {
//     let metadata = await getMetadata(item);
//     let url = await getDownloadURL(item);
//     buyerAdditionalDocuments.push({
//       url,
//       type: metadata.contentType,
//       name: metadata.name,
//       path: `homebuyer_additional_documents/${homebuyerId}/${metadata.name}`,
//       ...metadata,
//     });
//   }

//   if (coBuyerId) {
//     const coBuyerRef = ref(
//       storage,
//       `cobuyer_additional_documents/${coBuyerId}`
//     );

//     const coBuyerAdditionalDocumentsRes = await listAll(coBuyerRef);

//     for (let item of coBuyerAdditionalDocumentsRes.items) {
//       let metadata = await getMetadata(item);
//       let url = await getDownloadURL(item);
//       coBuyerAdditionalDocuments.push({
//         url,
//         type: metadata.contentType,
//         name: metadata.name,
//         path: `cobuyer_additional_documents/${coBuyerId}/${metadata.name}`,
//         ...metadata,
//       });
//     }
//   }

//   return { buyerAdditionalDocuments, coBuyerAdditionalDocuments };
// };

const getApplicationAdditionalDocuments = async (homebuyerId, coBuyerId) => {
	console.log('Running getApplicationAdditionalDocuments()');
	try {
		let buyerAdditionalDocuments = [],
			coBuyerAdditionalDocuments = [];

		const verifyRef = ref(
			storage,
			`homebuyer_additional_documents/${homebuyerId}`
		);

		const buyerAdditionalDocumentsRes = await listAll(verifyRef);

		for (let item of buyerAdditionalDocumentsRes.items) {
			let metadata = await getMetadata(item);
			let url = await getDownloadURL(item);
			buyerAdditionalDocuments.push({
				url,
				type: metadata.contentType,
				name: metadata.name,
				path: `homebuyer_additional_documents/${homebuyerId}/${metadata.name}`,
				...metadata,
			});
		}

		if (coBuyerId) {
			const coBuyerRef = ref(
				storage,
				`cobuyer_additional_documents/${homebuyerId}`
			);

			const coBuyerAdditionalDocumentsRes = await listAll(coBuyerRef);

			for (let item of coBuyerAdditionalDocumentsRes.items) {
				let metadata = await getMetadata(item);
				let url = await getDownloadURL(item);
				coBuyerAdditionalDocuments.push({
					url,
					type: metadata.contentType,
					name: metadata.name,
					path: `cobuyer_additional_documents/${homebuyerId}/${metadata.name}`,
					...metadata,
				});
			}
		}

		// Sort buyerAdditionalDocuments based on timeCreated
		buyerAdditionalDocuments.sort((a, b) => {
			const timeA = new Date(a.timeCreated).getTime();
			const timeB = new Date(b.timeCreated).getTime();
			return timeB - timeA;
		});

		// Sort coBuyerAdditionalDocuments based on timeCreated
		coBuyerAdditionalDocuments.sort((a, b) => {
			const timeA = new Date(a.timeCreated).getTime();
			const timeB = new Date(b.timeCreated).getTime();
			return timeB - timeA;
		});

		return { buyerAdditionalDocuments, coBuyerAdditionalDocuments };
	} catch (err) {
		console.log('Error getApplicationAdditionalDocuments(): ', err);
		const buyerAdditionalDocuments = [];
		const coBuyerAdditionalDocuments = [];

		return { buyerAdditionalDocuments, coBuyerAdditionalDocuments };
	}
};

const updateApplicationWithSubmissionsReset = async () => {
	console.log('Running updateApplicationWithSubmissionsReset()');
	try {
		console.log('Updating documents');

		const querySnapshot = await getDocs(
			collection(db, 'homebuyer_application_responses')
		);
		querySnapshot.forEach(async (d) => {
			const applicationRef = doc(
				db,
				'homebuyer_application_responses',
				d.id
			);

			await updateDoc(applicationRef, {
				addCoBuyerAfterSubmission: false,
			});

			console.log('Updated document with id: ', d.id, 'successfully');
		});

		await handleGetHomebuyerApplicationResponses();
	} catch (err) {
		console.log('Error updateApplicationWithSubmissionsReset: ', err);
		return null;
	}
};

const updateApplicationWithSubmissions = async (uid) => {
	console.log('Running updateApplicationWithSubmissions()');
	try {
		const auth = getAuth();

		const applicationRef = doc(db, 'homebuyer_application_responses', uid);
		const applicationSnap = await getDoc(applicationRef);

		const usersRef = doc(db, 'users', uid);
		const usersSnap = await getDoc(usersRef);

		const user = auth.currentUser;
		const userId = user.uid;
		const userEmail = user.email;

		if (applicationSnap.exists() && usersSnap.exists()) {
			await updateDoc(applicationRef, {
				addCoBuyerAfterSubmission:
					!applicationSnap.data().addCoBuyerAfterSubmission,
				alertedAdmin:
					!applicationSnap.data().addCoBuyerAfterSubmission === true
						? {
								id: userId,
								email: userEmail,
						  }
						: null,
			});

			if (!applicationSnap.data().addCoBuyerAfterSubmission === true) {
				await axios.post(
					'https://utopia-server.onrender.com/api/v1/email/homebuyer_adding_cobuyer',
					{
						buyerEmail: usersSnap.data().email,
						clientName:
							usersSnap.data().legalName.firstName === null
								? ''
								: usersSnap.data().legalName.firstName,
						inviteEndpoint: 'https://dash.utopiahomes.us/',
					},
					{
						headers: {
							'Content-Type': 'application/json',
						},
					}
				);

				console.log(`Email sent to ${usersSnap.data().email}`);
			}

			console.log('Document submission updated');
		} else {
			console.log('No such document!');
		}

		await handleGetHomebuyerApplicationResponses();
	} catch (err) {
		console.log('Error updateApplicationWithSubmissions(): ', err);
		return null;
	}
};

// const createCoBuyerApplication = async ({
// cobuyerFirstName,
// cobuyerLastName,
// cobuyerEmail,
// homebuyerId,
// homebuyerFirstName,
// homebuyerLastName,
// homebuyerEmail,
// }) => {
//   try {
//   } catch (err) {
//     console.log('Error createCoBuyerApplication(): ', err)
//   }
// }

const createCoBuyerApplication = async (
	cobuyerFirstName,
	cobuyerLastName,
	cobuyerEmail,
	homebuyerId,
	homebuyerFirstName,
	homebuyerLastName,
	homebuyerEmail
) => {
	console.log('Running createCoBuyerApplication()');
	try {
		if (
			cobuyerFirstName &&
			cobuyerLastName &&
			cobuyerEmail &&
			homebuyerId &&
			homebuyerFirstName &&
			homebuyerLastName &&
			homebuyerEmail
		) {
			const docRef = await addDoc(
				collection(db, 'coapplicant_application_responses'),
				{
					applicationCompleted: false,
					email: cobuyerEmail,
					name: {
						firstName: cobuyerFirstName,
						lastName: cobuyerLastName,
					},
					homebuyer: homebuyerId,
					homebuyerEmail: homebuyerEmail,
					homebuyerName: {
						firstName: homebuyerFirstName,
						lastName: homebuyerLastName,
					},
				}
			);

			await axios({
				method: 'post',
				url: `${`https://utopia-server.onrender.com`}/api/v1/email/coapplicant_email_from_user`,
				headers: {
					'Content-Type': 'application/json',
				},
				data: JSON.stringify({
					coapplicantFirstName:
						capitalizeFirstLetter(cobuyerFirstName),
					coapplicantLastName: capitalizeFirstLetter(cobuyerLastName),
					userFirstName: capitalizeFirstLetter(homebuyerFirstName),
					userLastName: capitalizeFirstLetter(homebuyerLastName),
					submissionDate: getFormattedDate(
						new Date(Timestamp.now().seconds * 1000)
					),
					emailTo: cobuyerEmail,
					coapplicantLink: `${process.env.REACT_APP_WEB_URL}/coapplicant/onboarding/${docRef.id}`,
				}),
			});

			return true;
		}

		return false;
	} catch (err) {
		console.log('Error createCoBuyerApplication(): ', err);
		return false;
	}
};

const capitalizeFirstLetter = (string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
};

const getFormattedDate = (date) => {
	var year = date.getFullYear();

	var month = (1 + date.getMonth()).toString();
	month = month.length > 1 ? month : '0' + month;

	var day = date.getDate().toString();
	day = day.length > 1 ? day : '0' + day;

	return month + '/' + day + '/' + year;
};

const getHomebuyerById = async (userId) => {
	console.log('Running getHomebuyerById()');
	try {
		const docRef = doc(db, 'users', userId);
		const docSnap = await getDoc(docRef);

		const data = docSnap.data();
		const response = {
			...data,
			id: docSnap.id,
			applicationStartDate: data?.applicationStartDate
				? moment
						.tz(
							data?.applicationStartDate?.toDate(),
							'America/New_York'
						)
						.toDate()
				: null,
			displayId: data?.user_id,
			applicationStatus: data?.applicationCompleted
				? 'Completed'
				: 'InProgress',
			firstName: data.legalName?.firstName,
			lastName: data.legalName?.lastName,
		};

		return response;
	} catch (err) {
		console.log('Error getHomebuyerById(): ', err);
		return null;
	}
};

const getPropertyApplicationFilesByApplicationId = async (
	userId,
	applicationId
) => {
	try {
		console.log('Running getPropertyApplicationFilesByApplicationId()');

		const salesCmaDocsRef = ref(
			storage,
			`landlord_property_files_optimized/${userId}/${applicationId}/sales_cma_docs`
		);
		const salesCmaDocs = await listAll(salesCmaDocsRef);

		let salesCmaFiles = [];
		for (let item of salesCmaDocs.items) {
			const fileMetadata = await getMetadata(item);
			const fileUrl = await getDownloadURL(item);

			salesCmaFiles.push({
				fileUrl: fileUrl,
				fileName: fileMetadata?.name,
				fileType: fileMetadata?.contentType,
			});
		}

		const appraisalReportDocsRef = ref(
			storage,
			`landlord_property_files_optimized/${userId}/${applicationId}/appraisal_report_docs`
		);
		const appraisalReportDocs = await listAll(appraisalReportDocsRef);

		let appraisalReportFiles = [];
		for (let item of appraisalReportDocs.items) {
			const fileMetadata = await getMetadata(item);
			const fileUrl = await getDownloadURL(item);

			appraisalReportFiles.push({
				fileUrl: fileUrl,
				fileName: fileMetadata?.name,
				fileType: fileMetadata?.contentType,
			});
		}

		const propertyImagesRef = ref(
			storage,
			`landlord_property_files_optimized/${userId}/${applicationId}/property_images`
		);
		const propertyImagesDoc = await listAll(propertyImagesRef);

		let propertyImages = [];
		for (let item of propertyImagesDoc.items) {
			const fileMetadata = await getMetadata(item);
			const fileUrl = await getDownloadURL(item);

			propertyImages.push({
				imageUrl: fileUrl,
				imageDescription:
					fileMetadata?.customMetadata?.propertyDescription,
			});
		}

		return {
			propertyImages: propertyImages,
			salesCmaDocs: salesCmaFiles,
			appraisalReportDocs: appraisalReportFiles,
		};
	} catch (err) {
		console.log(
			'Error getPropertyApplicationFilesByApplicationId(): ',
			err
		);
		return null;
	}
};

const getInitiatedProperties = async () => {
	console.log('Running getInitiatedProperties()');
	try {
		let dataList = [];

		const docsRef = collection(db, 'property_interest');
		const docsSnap = await getDocs(docsRef);

		for (const document of docsSnap.docs) {
			const data = document.data();
			const id = document.id;

			const userId = data.userId;
			const propertyId = data.propertyId;
			const listerId = data.listerId;

			if (!userId || !propertyId || !listerId) {
				console.warn(`Skipping document ${id} due to missing ID`, {
					userId,
					propertyId,
					listerId,
				});
				continue;
			}

			let userData = {},
				listerData = {},
				propertyData = {};

			try {
				// Get user data from users table
				const userDocRef = doc(db, 'users', userId);
				const userDocSnap = await getDoc(userDocRef);
				userData = userDocSnap.data() || {};
			} catch (error) {
				console.error(`Error fetching user data for ${userId}:`, error);
			}

			// try {
			// 	// Get lister data from users table
			// 	const listerDocRef = doc(db, 'users', listerId);
			// 	const listerDocSnap = await getDoc(listerDocRef);
			// 	listerData = listerDocSnap.data() || {};
			// } catch (error) {
			// 	console.error(
			// 		`Error fetching lister data for ${listerId}:`,
			// 		error
			// 	);
			// }

			try {
				// Get property data from properties table
				const propertyDocRef = doc(
					db,
					'landlord_properties',
					propertyId
				);
				const propertyDocSnap = await getDoc(propertyDocRef);
				propertyData = propertyDocSnap.data() || {};
			} catch (error) {
				console.error(
					`Error fetching property data for ${propertyId}:`,
					error
				);
			}

			dataList.push({
				id,
				...data,
				userData,
				listerData,
				propertyData,
			});
		}

		dataList.sort((a, b) => {
			return b.createdAt - a.createdAt;
		});

		return dataList;
	} catch (err) {
		console.log('Error getInitiatedProperties(): ', err);
		return []; // Return an empty array in case of error
	}
};

const getAgentDataById = async (userId) => {
	console.log('Running getAgentDataById()');
	try {
		const docRef = doc(db, 'agent_application_responses', userId);
		const docSnap = await getDoc(docRef);

		const data = docSnap.data();

		return data;
	} catch (err) {
		console.log('Error getAgentDataById(): ', err);
		return null;
	}
};

const getLandlordDataById = async (userId) => {
	console.log('Running getLandlordDataById()');
	try {
		const docRef = doc(db, 'landlord_application_responses', userId);
		const docSnap = await getDoc(docRef);

		const data = docSnap.data();

		return data || null;
	} catch (err) {
		console.log('Error getLandlordDataById(): ', err);
		return null;
	}
};

const sendApprovedEmails = async (userId, propertyId) => {
	console.log('Running sendApprovedEmails()');
	try {
		await axios.post(
			'https://utopia-server.onrender.com/api/v1/email/landlord/property_approved',
			{
				userId: userId,
				propertyId: propertyId,
			},
			{
				headers: {
					'Content-Type': 'application/json',
				},
			}
		);

		await axios.post(
			'https://utopia-server.onrender.com/api/v1/email/landlord/property_approved_confirmation_to_agent',
			{
				userId: userId,
				propertyId: propertyId,
			},
			{
				headers: {
					'Content-Type': 'application/json',
				},
			}
		);
	} catch (err) {
		console.log('Error sendApprovedEmails(): ', err);
	}
};

const deleteProperty = async (propertyId) => {
	console.log('Running deleteProperty()');
	try {
		const docRef = doc(db, 'landlord_properties', propertyId);
		await deleteDoc(docRef);

		console.log('Property deleted successfully: ', propertyId);
	} catch (err) {
		console.log('Error deleteProperty(): ', err);
	}
};

const deleteLandlord = async (landlordData) => {
	console.log('Running deleteLandlord()');
	try {
		const properties = landlordData?.properties || [];
		for (let i = 0; i < properties.length; i++) {
			const currentProperty = properties[i];
			const docRef = doc(db, 'landlord_properties', currentProperty?.id);
			await deleteDoc(docRef);
		}

		const docRef = doc(
			db,
			'landlord_application_responses',
			landlordData?.userData?.id
		);
		await deleteDoc(docRef);

		const docRef2 = doc(db, 'users', landlordData?.userData?.id);
		await deleteDoc(docRef2);

		return true;
	} catch (err) {
		console.log('Error deleteLandlord(): ', err);

		return false;
	}
};

const getAllLandlords = async () => {
	console.log('Running getAllLandlords()');
	try {
		const landlordsSnapshot = await getDocs(
			query(collection(db, 'users'), where('type', '==', 'landlord'))
		);
		const allLandlordsPromises = landlordsSnapshot.docs.map(
			async (landlord) => {
				const landlordId = landlord.id;
				const landlordData = landlord.data();
				const [landlordPropertiesSnapshot, landlordApplication] =
					await Promise.all([
						getCountFromServer(
							query(
								collection(db, 'landlord_properties'),
								where('userId', '==', landlordId)
							)
						),
						getDoc(
							doc(
								db,
								'landlord_application_responses',
								landlordId
							)
						),
					]);
				const numberOfProperties =
					landlordPropertiesSnapshot.data().count;
				const landlordApplicationData = landlordApplication?.data();
				let applicationCompletedTime = null;
				if (landlordApplicationData) {
					applicationCompletedTime =
						landlordApplicationData?.timeSubmitted
							? moment(
									landlordApplicationData.timeSubmitted.toDate()
							  ).format('MM/DD/YYYY')
							: null;
				}
				return {
					...landlordData,
					numberOfProperties,
					applicationCompletedTime,
				};
			}
		);
		const allLandlords = await Promise.all(allLandlordsPromises);
		return allLandlords;
	} catch (err) {
		console.log('Error getAllLandlords(): ', err);
	}
};

export {
	getAllUsers,
	getApplicationByUserId,
	getUserFilesById,
	getUserById,
	getUserByDisplayId,
	downloadFile,
	getAllApplicationsData,
	changeUserStatusToDeleted,
	getPieChartStats,
	getGraphsDataBasedOnDateRange,
	updateApplicationResponseFields,
	updateUserFields,
	getMonthlyStats,
	getHomebuyerFilesById,
	getCoapplicantData,
	getCoapplicantStorageFiles,
	getUserByEmail,
	getUsersWithApplicationsByEmails,
	updateInvitationStatus,
	getUserApplication,
	getPieChartStatsByDateRange,
	getApplicationAdditionalDocuments,
	updateApplicationWithSubmissions,
	updateApplicationWithSubmissionsReset,
	createCoBuyerApplication,
	updateCoApplicationResponseFields,
	getHomebuyerById,
	getCoapplicantData_Optimized,
	getUserByEmail_Optimized,
	getMonthlyStats_New,
	updatePropertyApplicationDetails,
	getPropertyApplicationFilesByApplicationId,
	getAgentDataById,
	getLandlordDataById,
	sendApprovedEmails,
	deleteProperty,
	deleteLandlord,
	getAllLandlords,
	getLandlordandPropertyData,
};
