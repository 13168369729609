import React, { Fragment, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import * as dayjs from 'dayjs';
import { setLandlords } from 'store/reducers/usersSlice';
import { getAllLandlords } from 'services/applications.service';

// context
import { useAuth } from 'contexts/AuthContext';

// components
import ConfirmationDialog from 'components/confirmationDialog';
import CsvDownloadButton from 'react-json-to-csv';
import { DatePicker } from 'components/datePicker/DatePicker';
import UsersList from './components/UsersList';

// chakra
import {
	Box,
	Table,
	Thead,
	Tbody,
	Tfoot,
	Tr,
	Th,
	Td,
	TableCaption,
	TableContainer,
	Button,
	IconButton,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	useToast,
	Flex,
	Container,
	Stack,
	Text,
	Input,
	StackDivider,
	Image,
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';

var utc = require('dayjs/plugin/utc');
var timezone = require('dayjs/plugin/timezone');

const tz = 'America/New_York';

dayjs.extend(utc);
dayjs.extend(timezone);

const Landlords = () => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const toast = useToast();

	const [landlordApplications, setLandlordApplications] = useState([]);

	const [selectedUser, setSelectedUser] = useState(null);
	const [applicationData, setApplicationData] = useState(null);
	const [storageData, setStorageData] = useState();
	const [loading, setLoading] = useState(false);
	const [filteredData, setFilteredData] = useState(null);
	const [userName, setUserName] = useState('');
	const [dialogLoading, setDialogLoading] = useState(false);

	const { landlords } = useSelector((state) => state.user);

	const { searchResults } = useAuth();

	const dispatch = useDispatch();

	const getStorageData = async (userId) => {
		try {
			setLoading(true);
			// const storageRes = await getUserFilesById(userId);
			// setStorageData(storageRes);
			setLoading(false);
		} catch (err) {
			setLoading(false);

			toast({
				title: 'Error',
				description:
					'Something went wrong while getting user files. Please try again',
				status: 'error',
				duration: 4000,
				isClosable: true,
				position: 'top-right',
			});
		}
	};

	const removeUserFromUsers = (userId) => {
		const ind = landlords.findIndex((user) => {
			return user.id === userId;
		});
		let temp = [...landlords];
		temp.splice(ind, 1);
		dispatch(setLandlords(temp));
	};

	const handleConfirmationOpen = (userId, name) => {
		// setUserName(name);
		// setDeleteId(userId);
		onOpen();
	};

	const handleDelete = async () => {
		try {
			// setDialogLoading(true);
			// await changeAgentStatusToDeleted(deleteId);
			// removeUserFromUsers(deleteId);
			// setDeleteId(null);
			// setDialogLoading(false);
			onClose();
			toast({
				title: 'Success',
				description: 'User deleted successfully',
				status: 'success',
				duration: 4000,
				isClosable: true,
				position: 'top-right',
			});
		} catch (err) {
			// setDeleteId(null);
			// setDialogLoading(false);
			onClose();

			toast({
				title: 'Error',
				description:
					'Unable to delete application at the moment. Please try again later',
				status: 'error',
				duration: 4000,
				isClosable: true,
				position: 'top-right',
			});
		}
	};

	const [downloadData, downloadHeaders] = useMemo(() => {
		let data = filteredData ?? landlords;
		let downloadData = data.map((val, index) => {
			return {
				'#': index,
				id: val.displayId,
				firstName: val.firstName,
				lastName: val.lastName,
				email: val.email,
				phone: val.phoneNumber,
				applicationCompleted: val.applicationCompleted,
				applicationStatus: val.applicationStatus,
			};
		});

		const headers = [
			'#',
			'id',
			'firstName',
			'lastName',
			'email',
			'phone',
			'applicationCompleted',
			'applicationStatus',
		];

		return [downloadData, headers];
	}, [landlords, filteredData]);

	const handleDateFilter = (values) => {
		console.log('RUNNING handleDateFilter()');

		if (values?.start && values?.end) {
			const startDate = new Date(values.start);
			const endDate = new Date(values.end);
			endDate.setHours(23, 59, 59, 999);

			let tempLandlords = landlords.filter(
				(user) => user?.type === 'landlord'
			);

			console.log({ TEMP: tempLandlords });

			const response = tempLandlords?.filter((item) => {
				let dateKey = item?.applicationCompletedTime;

				const submissionDate = new Date(dateKey);

				// Convert submissionDate, startDate, and endDate to UTC before comparison
				const submissionDateUTC = Date.UTC(
					submissionDate.getFullYear(),
					submissionDate.getMonth(),
					submissionDate.getDate()
				);
				const startDateUTC = Date.UTC(
					startDate.getFullYear(),
					startDate.getMonth(),
					startDate.getDate()
				);
				const endDateUTC = Date.UTC(
					endDate.getFullYear(),
					endDate.getMonth(),
					endDate.getDate()
				);

				return (
					submissionDateUTC >= startDateUTC &&
					submissionDateUTC <= endDateUTC
				);
			});

			setFilteredData(response);
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			setLandlordApplications(landlords);
		};

		fetchData().catch(console.error);
	}, [landlords]);

	return (
		<Fragment>
			{/* <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
				<Box
					bg="white"
					rounded="lg"
					p="4"
				>
					<TableContainer>
						<Table variant="simple">
							<Thead>
								<Tr>
									<Th>#</Th>
									<Th>Email</Th>
									<Th>First Name</Th>
									<Th>Last Name</Th>
									<Th>Phone Number</Th>
									<Th>Status</Th>
									<Th>Time Completed</Th>
									<Th>Properties Uploaded</Th>
								</Tr>
							</Thead>

							<Tbody>
								{landlords?.map((user, index) => (
									<Tr key={index}>
										<Td>{index + 1}</Td>
										<Td>{user?.userData?.email}</Td>
										<Td>
											{user?.userData?.application
												?.stepOne?.[0]?.answer || '-'}
										</Td>
										<Td>
											{user?.userData?.application
												?.stepOne?.[1]?.answer || '-'}
										</Td>
										<Td>
											{user?.userData?.application
												?.stepOne?.[2]?.answer || '-'}
										</Td>
										<Td>
											{user?.userData?.application
												?.applicationCompleted
												? 'Completed'
												: user?.userData?.application
														?.applicationCompleted
												? 'Started'
												: 'Not Started'}
										</Td>
										<Td>
											{
												user?.userData?.application
													?.applicationCompletedTime
											}
										</Td>
										<Td>
											{user?.properties
												? user?.properties?.length
												: 0}
										</Td>
									</Tr>
								))}
							</Tbody>
						</Table>
					</TableContainer>
				</Box>
			</Box>

			<Modal
				isOpen={isOpen}
				onClose={onClose}
				size="full"
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Edit Property</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Stack
							spacing="6"
							divider={<StackDivider borderColor="gray.200" />}
						>
							<Box>
								<Text
									fontWeight="600"
									fontSize="lg"
									mb="2"
								>
									Step 1:
								</Text>

								<Stack spacing="3">
									<Box
										bg="gray.200"
										p="4"
										rounded="lg"
									>
										<Text mb="2">Property Type</Text>
										<Input value="Single-family Home" />
									</Box>

									<Box
										bg="gray.200"
										p="4"
										rounded="lg"
									>
										<Text mb="2">Bedrooms</Text>
										<Input value="3" />
									</Box>

									<Box
										bg="gray.200"
										p="4"
										rounded="lg"
									>
										<Text mb="2">Baths</Text>
										<Input value="4" />
									</Box>

									<Box
										bg="gray.200"
										p="4"
										rounded="lg"
									>
										<Text mb="2">Monthly Rent</Text>
										<Input value="1000" />
									</Box>

									<Box
										bg="gray.200"
										p="4"
										rounded="lg"
									>
										<Text mb="2">Square Feet</Text>
										<Input value="1000" />
									</Box>

									<Box
										bg="gray.200"
										p="4"
										rounded="lg"
									>
										<Text mb="2">Year Built</Text>
										<Input value="2002" />
									</Box>
								</Stack>
							</Box>

							<Box>
								<Text
									fontWeight="600"
									fontSize="lg"
									mb="2"
								>
									Step 2:
								</Text>

								<Stack spacing="3">
									<Box
										bg="gray.200"
										p="4"
										rounded="lg"
									>
										<Text mb="2">State</Text>
										<Input value="Texas" />
									</Box>

									<Box
										bg="gray.200"
										p="4"
										rounded="lg"
									>
										<Text mb="2">Metro</Text>
										<Input value="Dallas" />
									</Box>

									<Box
										bg="gray.200"
										p="4"
										rounded="lg"
									>
										<Text mb="2">City</Text>
										<Input value="" />
									</Box>

									<Box
										bg="gray.200"
										p="4"
										rounded="lg"
									>
										<Text mb="2">
											Property Address or Zillow/Redfin
											Link
										</Text>
										<Input value="116 Collin Court, Murphy, TX, 75094" />
									</Box>
								</Stack>
							</Box>

							<Box>
								<Text
									fontWeight="600"
									fontSize="lg"
									mb="2"
								>
									Step 3:
								</Text>

								<Stack spacing="3">
									<Box
										bg="gray.200"
										p="4"
										rounded="lg"
									>
										<Text mb="2">Value of Property</Text>
										<Input value="200000" />
									</Box>

									<Box
										bg="gray.200"
										p="4"
										rounded="lg"
									>
										<Text mb="2">Reason for Value</Text>
										<Input value="This is the value of the property." />
									</Box>
								</Stack>
							</Box>

							<Box>
								<Text
									fontWeight="600"
									fontSize="lg"
									mb="2"
								>
									Step 4:
								</Text>

								<Stack spacing="3">
									<Box
										bg="gray.200"
										p="4"
										rounded="lg"
									>
										<Text mb="2">Property Description</Text>
										<Input value="This is the description of the property." />
									</Box>
								</Stack>
							</Box>

							<Box>
								<Text
									fontWeight="600"
									fontSize="lg"
									mb="2"
								>
									Step 5:
								</Text>

								<Stack spacing="3">
									<Box
										bg="gray.200"
										p="4"
										rounded="lg"
									>
										<Text mb="2">Sales CMA</Text>
										<Input value="No uploaded files" />
									</Box>

									<Box
										bg="gray.200"
										p="4"
										rounded="lg"
									>
										<Text mb="2">Appraisal Report</Text>
										<Input value="No uploaded files" />
									</Box>
								</Stack>

								<Stack spacing="3">
									<Box
										bg="gray.200"
										p="4"
										rounded="lg"
									>
										<Text mb="2">Property Images</Text>
										<Stack spacing="3">
											<Flex align="center">
												<Image
													src="https://www.redfin.com/blog/wp-content/uploads/2021/06/NYC3.jpg"
													alt="property"
													maxW="100px"
													mr="4"
												/>
												<Text>Living room</Text>
											</Flex>

											<Flex align="center">
												<Image
													src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRhdDm_iYl5yccPMMhUgLldFtdG1tulY_JS3Q&s"
													alt="property"
													maxW="100px"
													mr="4"
												/>
												<Text>Kitchen</Text>
											</Flex>
										</Stack>
									</Box>
								</Stack>
							</Box>
						</Stack>
					</ModalBody>

					<ModalFooter>
						<Button
							colorScheme="red"
							mr={3}
							onClick={onClose}
						>
							Close
						</Button>
						<Button
							colorScheme="blue"
							onClick={onClose}
						>
							Save
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal> */}

			<Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
				<ConfirmationDialog
					title="Delete Application"
					message={`Are you sure you want to delete <b>${userName}</b> application?`}
					isOpen={isOpen}
					onOpen={onOpen}
					onClose={onClose}
					loading={dialogLoading}
					onConfirm={() => handleDelete()}
				/>
				<Flex
					justifyContent={'space-between'}
					mb={2}
				>
					<Button>
						<CsvDownloadButton
							data={downloadData}
							filename={`Agents-Export-${moment(new Date())
								.tz('America/New_York')
								.format('DD/MM/YY')}`}
							delimiter=","
							headers={downloadHeaders}
						/>
					</Button>
					<DatePicker
						closeAction={handleDateFilter}
						clearAction={() => {
							setFilteredData(null);
						}}
					/>
				</Flex>

				<UsersList
					data={
						searchResults
							? searchResults
							: filteredData
							? filteredData
							: landlords
					}
					// data={filteredData ? filteredData : landlords}
					setSelectedUser={setSelectedUser}
					storageData={storageData}
					setStorageData={setStorageData}
					selectedUser={selectedUser}
					loading={loading}
					applicationData={applicationData}
					setApplicationData={setApplicationData}
					getStorageData={getStorageData}
					handleDelete={handleConfirmationOpen}
				/>
			</Box>

			{/* <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
				<Text>hhihi</Text>
			</Box> */}
		</Fragment>
	);
};

export default Landlords;
