import { DeleteIcon } from '@chakra-ui/icons';
import CustomTable from 'components/customTable';
import moment from 'moment-timezone';
import React, { useState, useEffect } from 'react';
import ApplicationDetails from './ApplicationDetails';
import { deleteLandlord } from '../../../../services/applications.service';

// chakra
import {
	Button,
	Text,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	Box,
	useToast,
} from '@chakra-ui/react';

const UsersList = (props) => {
	const {
		data,
		setSelectedUser,
		selectedUser,
		loading,
		getStorageData,
		applicationData,
		storageData,
		setStorageData,
		setApplicationData,
		handleDelete,
		userLoading,
	} = props;

	const { isOpen, onOpen, onClose } = useDisclosure();
	const toast = useToast();

	const [tableData, setTableData] = useState(false);
	const [selectedLandlord, setSelectedLandlord] = useState(null);
	const [deleteLoading, setDeleteLoading] = useState(false);

	const closeModal = () => {
		setSelectedUser(null);
		setStorageData(null);
	};

	const columns = React.useMemo(
		() => [
			{
				header: () => <span>#</span>,
				accessorKey: 'serialNo',
				enableSorting: false,
				cell: (row) => {
					return <div>{row?.row?.index + 1}</div>;
				},
			},
			{
				accessorKey: 'userType',
				header: () => <span>User Type</span>,
				footer: (props) => props.column.id,
			},
			{
				accessorKey: 'firstName',
				header: () => <span>First Name</span>,
				footer: (props) => props.column.id,
			},
			{
				accessorKey: 'lastName',
				header: () => <span>Last Name</span>,
				footer: (props) => props.column.id,
			},
			{
				accessorKey: 'email',
				header: () => <span>Email</span>,
				footer: (props) => props.column.id,
			},
			{
				accessorKey: 'phoneNumber',
				header: () => <span>Phone Number</span>,
				footer: (props) => props.column.id,
				sortType: 'number',
				cell: (row) => {
					let value = row.getValue();
					return <Text>{value?.toLocaleString()}</Text>;
				},
			},
			{
				accessorKey: 'status',
				header: () => <span>Status</span>,
				footer: (props) => props.column.id,
			},
			{
				accessorKey: 'submissionDate',
				header: () => <span>Submission Date</span>,
				footer: (props) => props.column.id,
				sortType: 'datetime',
				cell: (row) => {
					let value = row.getValue();
					return (
						<Text>
							{value
								? moment(value)
										.tz('America/New_York')
										.format('DD MMM YYYY,  hh:mm a')
								: ''}
						</Text>
					);
				},
			},
			{
				accessorKey: 'properties',
				header: () => <span>Properties</span>,
				footer: (props) => props.column.id,
			},
			{
				accessorKey: 'actions',
				header: () => <span>Actions</span>,
				footer: (props) => props.column.id,
				cell: (row) => {
					return (
						<Button
							width={'100%'}
							maxH={'25px'}
							background="transparent"
						>
							<DeleteIcon color="red.400" />
						</Button>
					);
				},
			},
		],
		[]
	);

	useEffect(() => {
		if (data) {
			let tableList = [];
			for (let i = 0; i < data.length; i++) {
				const currentData = data[i];
				const userType = currentData?.type;

				if (userType === 'landlord') {
					tableList.push({
						id: i,
						landlordId: currentData?.uid,
						userType: currentData?.type,
						firstName:
							currentData?.applicationCompleted === true
								? currentData?.application?.stepOne[0].answer
								: '',
						lastName:
							currentData?.applicationCompleted === true
								? currentData?.application?.stepOne[1].answer
								: '',
						email: currentData?.application?.email
							? currentData?.application?.email
							: currentData?.email
							? currentData?.email
							: '',
						phoneNumber:
							currentData?.applicationCompleted === true
								? currentData?.application?.stepOne[2].answer
								: '',
						submissionDate: currentData?.applicationCompletedTime,
						status:
							currentData?.applicationCompleted === true
								? 'Completed'
								: currentData?.applicationStarted === true
								? 'Started'
								: 'Not Started',
						properties: currentData?.numberOfProperties,
						userData: currentData,
					});
				} else {
					// tableList.push({
					// 	id: i,
					// 	userType: currentData?.userData?.type,
					// 	firstName: currentData?.userData?.legalName?.firstName,
					// 	lastName: currentData?.userData?.legalName?.lastName,
					// 	email: currentData?.userData?.email,
					// 	phoneNumber: 'N/A',
					// 	submissionDate: 'N/A',
					// 	status: 'N/A',
					// 	properties: currentData?.properties?.length,
					// });
				}
			}
			setTableData(tableList);
		}
	}, [data]);

	console.log({
		tableData,
	});

	return (
		<>
			<CustomTable
				data={tableData}
				columns={columns}
				onRowPress={(data) => {
					getStorageData(tableData.id);
					setSelectedUser(tableData);
					setApplicationData(tableData?.application);
				}}
				loading={userLoading}
				onActionPress={(tableData) => {
					onOpen();
					setSelectedLandlord(tableData);
				}}
			/>

			<Modal
				isOpen={isOpen}
				onClose={() => {
					onClose();
					setSelectedLandlord(null);
				}}
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>
						Are you sure you want to delete{' '}
						{selectedLandlord?.email}?
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Box>This action cannot be undone.</Box>
					</ModalBody>

					<ModalFooter>
						<Button
							colorScheme="red"
							mr={3}
							onClick={async () => {
								try {
									setDeleteLoading(true);
									const response = await deleteLandlord(
										selectedLandlord?.userData
									);

									if (response === true) {
										let currentTableData = tableData;
										currentTableData =
											currentTableData.filter(
												(item) =>
													item?.userData?.userData
														?.id !==
													selectedLandlord?.userData
														?.userData?.id
											);
										setTableData(currentTableData);

										toast({
											title: 'Landlord deleted.',
											description:
												'Landlord deleted successfully.',
											status: 'success',
											duration: 4500,
											isClosable: true,
											position: 'top-right',
										});
									} else {
										toast({
											title: 'Error',
											description:
												'Something went wrong. Please try again later.',
											status: 'error',
											duration: 4500,
											isClosable: true,
											position: 'top-right',
										});
										throw new Error('Something went wrong');
									}

									onClose();
									setSelectedLandlord(null);
									setDeleteLoading(false);
								} catch (err) {
									console.log(err);
									onClose();
									setSelectedLandlord(null);
									setDeleteLoading(false);
								}
							}}
							isLoading={deleteLoading}
						>
							Delete
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>

			{/* <ApplicationDetails
				closeModal={closeModal}
				selectedUser={selectedUser}
				isOpen={Boolean(selectedUser)}
				loading={loading}
				applicationData={applicationData}
				storageData={storageData}
			/> */}
		</>
	);
};

export default UsersList;
