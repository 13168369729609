// import React, { Fragment, useEffect, useState } from 'react';
// import { useSelector } from 'react-redux';
// import { deleteProperty } from 'services/applications.service';
// import moment from 'moment-timezone';
// import { useLocation } from 'react-router-dom';

// // components
// import PropertyModal from './components/PropertyModal';

// // chakra
// import {
// 	Box,
// 	Table,
// 	Thead,
// 	Tbody,
// 	Tfoot,
// 	Tr,
// 	Th,
// 	Td,
// 	TableCaption,
// 	TableContainer,
// 	Button,
// 	IconButton,
// 	Modal,
// 	ModalOverlay,
// 	ModalContent,
// 	ModalHeader,
// 	ModalFooter,
// 	ModalBody,
// 	ModalCloseButton,
// 	useDisclosure,
// 	useToast,
// 	Flex,
// 	Container,
// 	Stack,
// 	Text,
// 	Input,
// 	StackDivider,
// 	Image,
// 	Select,
// 	Spinner,
// 	Center,
// } from '@chakra-ui/react';
// import { DeleteIcon } from '@chakra-ui/icons';

// const Properties = () => {
// 	const { isOpen, onOpen, onClose } = useDisclosure();
// 	const {
// 		isOpen: isDeleteOpen,
// 		onOpen: onDeleteOpen,
// 		onClose: onDeleteClose,
// 	} = useDisclosure();

// 	const {
// 		properties,
// 		rejectedProperties,
// 		inReviewProperties,
// 		acceptedProperties,
// 	} = useSelector((state) => state.user);

// 	const route = useLocation();

// 	const [selectedProperty, setSelectedProperty] = useState(null);
// 	const [propertiesList, setPropertiesList] = useState([]);

// 	useEffect(() => {
// 		if (
// 			route.pathname === '/admin/properties/properties-all' ||
// 			route.pathname === '/admin/properties'
// 		) {
// 			setPropertiesList(properties);
// 		} else if (route.pathname === '/admin/properties/properties-rejected') {
// 			setPropertiesList(rejectedProperties);
// 		} else if (
// 			route.pathname === '/admin/properties/properties-in-review'
// 		) {
// 			setPropertiesList(inReviewProperties);
// 		} else if (route.pathname === '/admin/properties/properties-accepted') {
// 			setPropertiesList(acceptedProperties);
// 		}
// 	}, [
// 		route.pathname,
// 		properties,
// 		rejectedProperties,
// 		inReviewProperties,
// 		acceptedProperties,
// 	]);

// 	return (
// 		<Fragment>
// 			<Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
// 				<Box
// 					bg="white"
// 					rounded="lg"
// 					p="4"
// 				>
// 					<TableContainer>
// 						<Table variant="simple">
// 							<Thead>
// 								<Tr>
// 									<Th>#</Th>
// 									<Th>Address</Th>
// 									<Th>Status</Th>
// 									<Th>Time Submitted</Th>
// 									<Th>User Type</Th>
// 									<Th>User Email</Th>
// 									<Th>Action</Th>
// 								</Tr>
// 							</Thead>

// 							<Tbody>
// 								{propertiesList.map((property, index) => (
// 									<Tr>
// 										<Td>{index + 1}</Td>
// 										<Td>{property?.stepTwo[3]?.answer}</Td>
// 										<Td>{property?.status}</Td>
// 										<Td>
// 											{moment
// 												.tz(
// 													property?.timeSubmitted?.toDate(),
// 													'America/New_York'
// 												)
// 												.format('MM/DD/YYYY hh:mm A')}
// 										</Td>
// 										<Td>{property?.userData?.type}</Td>
// 										<Td>{property?.userData?.email}</Td>
// 										<Td>
// 											<Flex spacing={5}>
// 												<Button
// 													mr="4"
// 													onClick={() => {
// 														setSelectedProperty(
// 															property
// 														);
// 														onOpen();
// 													}}
// 												>
// 													Edit
// 												</Button>
// 												<Button
// 													colorScheme="red"
// 													onClick={() => {
// 														setSelectedProperty(
// 															property
// 														);
// 														onDeleteOpen();
// 													}}
// 												>
// 													Delete
// 												</Button>
// 											</Flex>
// 										</Td>
// 									</Tr>
// 								))}
// 							</Tbody>
// 						</Table>
// 					</TableContainer>
// 				</Box>
// 			</Box>

// 			{selectedProperty && (
// 				<PropertyModal
// 					isOpen={isOpen}
// 					onClose={onClose}
// 					selectedProperty={selectedProperty}
// 					setSelectedProperty={setSelectedProperty}
// 				/>
// 			)}

// 			<Modal
// 				isOpen={isDeleteOpen}
// 				onClose={() => {
// 					setSelectedProperty(null);
// 					onDeleteClose();
// 				}}
// 			>
// 				<ModalOverlay />
// 				<ModalContent>
// 					<ModalHeader>
// 						Delete Property: {selectedProperty?.stepTwo[3]?.answer}
// 					</ModalHeader>
// 					<ModalCloseButton />
// 					<ModalBody>
// 						<Box>
// 							Are you sure you want to delete this property? You
// 							cannot undo this action.
// 						</Box>
// 					</ModalBody>

// 					<ModalFooter>
// 						<Button
// 							colorScheme="gray"
// 							mr={3}
// 							onClick={() => {
// 								setSelectedProperty(null);
// 								onDeleteClose();
// 							}}
// 						>
// 							Cancel
// 						</Button>
// 						<Button
// 							colorScheme="red"
// 							onClick={async () => {
// 								await deleteProperty(selectedProperty?.id);
// 								setSelectedProperty(null);
// 								onDeleteClose();
// 								window.location.reload();
// 							}}
// 						>
// 							Delete
// 						</Button>
// 					</ModalFooter>
// 				</ModalContent>
// 			</Modal>
// 		</Fragment>
// 	);
// };

// export default Properties;

import React, { Fragment, useEffect, useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment-timezone';
import * as dayjs from 'dayjs';
import { useSelector } from 'react-redux';

// context
import { useAuth } from 'contexts/AuthContext';

// components
import ConfirmationDialog from 'components/confirmationDialog';
import CsvDownloadButton from 'react-json-to-csv';
import { DatePicker } from 'components/datePicker/DatePicker';
import PropertiesList from './components/PropertiesList';

// chakra
import {
	Box,
	Table,
	Thead,
	Tbody,
	Tfoot,
	Tr,
	Th,
	Td,
	TableCaption,
	TableContainer,
	Button,
	IconButton,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	useToast,
	Flex,
	Container,
	Stack,
	Text,
	Input,
	StackDivider,
	Image,
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';

var utc = require('dayjs/plugin/utc');
var timezone = require('dayjs/plugin/timezone');

const tz = 'America/New_York';

dayjs.extend(utc);
dayjs.extend(timezone);

const Properties = () => {
	const route = useLocation();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const toast = useToast();

	const {
		properties,
		rejectedProperties,
		inReviewProperties,
		acceptedProperties,
	} = useSelector((state) => state.user);

	const [propertiesList, setPropertiesList] = useState([]);

	const [selectedUser, setSelectedUser] = useState(null);
	const [applicationData, setApplicationData] = useState(null);
	const [storageData, setStorageData] = useState();
	const [loading, setLoading] = useState(false);
	const [filteredData, setFilteredData] = useState(null);
	const [userName, setUserName] = useState('');
	const [dialogLoading, setDialogLoading] = useState(false);

	// const { landlords } = useSelector((state) => state.user);

	const { searchResults } = useAuth();

	// const dispatch = useDispatch();

	const getStorageData = async (userId) => {
		try {
			setLoading(true);
			// const storageRes = await getUserFilesById(userId);
			// setStorageData(storageRes);
			setLoading(false);
		} catch (err) {
			setLoading(false);

			toast({
				title: 'Error',
				description:
					'Something went wrong while getting user files. Please try again',
				status: 'error',
				duration: 4000,
				isClosable: true,
				position: 'top-right',
			});
		}
	};

	const removeUserFromUsers = (userId) => {
		// const ind = landlords.findIndex((user) => {
		// 	return user.id === userId;
		// });
		// let temp = [...landlords];
		// temp.splice(ind, 1);
		// dispatch(setLandlords(temp));
	};

	const handleConfirmationOpen = (userId, name) => {
		// setUserName(name);
		// setDeleteId(userId);
		onOpen();
	};

	const handleDelete = async () => {
		try {
			// setDialogLoading(true);
			// await changeAgentStatusToDeleted(deleteId);
			// removeUserFromUsers(deleteId);
			// setDeleteId(null);
			// setDialogLoading(false);
			onClose();
			toast({
				title: 'Success',
				description: 'User deleted successfully',
				status: 'success',
				duration: 4000,
				isClosable: true,
				position: 'top-right',
			});
		} catch (err) {
			// setDeleteId(null);
			// setDialogLoading(false);
			onClose();

			toast({
				title: 'Error',
				description:
					'Unable to delete application at the moment. Please try again later',
				status: 'error',
				duration: 4000,
				isClosable: true,
				position: 'top-right',
			});
		}
	};

	// const [downloadData, downloadHeaders] = useMemo(() => {
	// 	let data = filteredData;
	// 	let downloadData = data.map((val, index) => {
	// 		return {
	// 			'#': index,
	// 			id: val.displayId,
	// 			firstName: val.firstName,
	// 			lastName: val.lastName,
	// 			email: val.email,
	// 			phone: val.phoneNumber,
	// 			applicationCompleted: val.applicationCompleted,
	// 			applicationStatus: val.applicationStatus,
	// 		};
	// 	});

	// 	const headers = [
	// 		'#',
	// 		'id',
	// 		'firstName',
	// 		'lastName',
	// 		'email',
	// 		'phone',
	// 		'applicationCompleted',
	// 		'applicationStatus',
	// 	];

	// 	return [downloadData, headers];
	// }, []);

	const handleDateFilter = (values) => {
		console.log('RUNNING handleDateFilter()');

		if (values?.start && values?.end) {
			const startDate = new Date(values.start);
			const endDate = new Date(values.end);
			endDate.setHours(23, 59, 59, 999);

			const response = propertiesList?.filter((item) => {
				let dateKey = item?.submissionDate;

				const submissionDate = new Date(dateKey);

				// Convert submissionDate, startDate, and endDate to UTC before comparison
				const submissionDateUTC = Date.UTC(
					submissionDate.getFullYear(),
					submissionDate.getMonth(),
					submissionDate.getDate()
				);
				const startDateUTC = Date.UTC(
					startDate.getFullYear(),
					startDate.getMonth(),
					startDate.getDate()
				);
				const endDateUTC = Date.UTC(
					endDate.getFullYear(),
					endDate.getMonth(),
					endDate.getDate()
				);

				return (
					submissionDateUTC >= startDateUTC &&
					submissionDateUTC <= endDateUTC
				);
			});

			setFilteredData(response);
		}
	};

	useEffect(() => {
		if (
			route.pathname === '/admin/properties/properties-all' ||
			route.pathname === '/admin/properties'
		) {
			setPropertiesList(properties);
		} else if (route.pathname === '/admin/properties/properties-rejected') {
			setPropertiesList(rejectedProperties);
		} else if (
			route.pathname === '/admin/properties/properties-in-review'
		) {
			setPropertiesList(inReviewProperties);
		} else if (route.pathname === '/admin/properties/properties-accepted') {
			setPropertiesList(acceptedProperties);
		}
	}, [
		route.pathname,
		properties,
		rejectedProperties,
		inReviewProperties,
		acceptedProperties,
	]);

	console.log({
		propertiesList,
	});

	return (
		<Fragment>
			<Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
				<Flex
					justifyContent={'space-between'}
					mb={2}
				>
					<Button>
						<CsvDownloadButton
							data={{}}
							filename={`Properties-Export-${moment(new Date())
								.tz('America/New_York')
								.format('DD/MM/YY')}`}
							delimiter=","
							headers={[]}
						/>
					</Button>
					<DatePicker
						closeAction={handleDateFilter}
						clearAction={() => {
							setFilteredData(null);
						}}
					/>
				</Flex>
				<PropertiesList
					data={
						searchResults
							? searchResults
							: filteredData
							? filteredData
							: propertiesList
					}
					setSelectedUser={setSelectedUser}
					storageData={storageData}
					setStorageData={setStorageData}
					selectedUser={selectedUser}
					loading={loading}
					applicationData={applicationData}
					setApplicationData={setApplicationData}
					getStorageData={getStorageData}
					handleDelete={handleConfirmationOpen}
				/>
			</Box>
		</Fragment>
	);
};

export default Properties;
